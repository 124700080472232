export const translation = {
    en: {
        "owner.profile.title": "Owner Profile",
        "owner.profile.header": "Edit Profile",
        "owner.profile.subheader": "Complete your profile",
        "owner.profile.emailAddress": "Email Address",
        "owner.profile.name": "Name",
        "owner.profile.id": "Identity Number",
        "owner.profile.dateOfBirth": "Date Of Birth",
        "owner.profile.gender": "Gender",
        "owner.profile.gender.male": "Male",
        "owner.profile.gender.famale": "Female",
        "owner.profile.phoneNumber": "Phone Number",
        "owner.profile.message": "* phone number must start from 6, eg: 60123456789",
        "owner.profile.update": "Update Profile",
        "owner.profile.upload.picture": "Upload Profile Picture"
    }
};
