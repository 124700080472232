export const translation = {
    en: {
        "complaint.title": "Complaint List",
        "complaint.tenantPhoneNo": "Tenant Phone No.",
        "complaint.status": "Complaint Status",
        "complaint.reportedDate": "Reported Date",
        "complaint.detail": "Complaint Details",
        "complaint.description": "Description",
        "complaint.happen.message": "When Happen",
        "complaint.notes": "Notes",
        "complaint.box.message": "Message: ",
        "complaint.box.status": "Status: ",
        "complaint.box.createdOn": "Created on: ",
        "complaint.box.empty": "No notes yet.",
        "complaint.box.leaveMessage": "Type here...",
        "complaint.box.validation.message": "Please enter message.",
        "complaint.dialog.leaveMessage": "Enter note for tenant: ",
        "complaint.dialog.validation.leaveMessage": "Please enter message."
    }
};
