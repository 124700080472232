import { constantType } from "../ActionType";

const INITIAL_STATE = {
    amenityList: [],
    amenityName: {},
    isLoadingConstant: false,
    errorConstant: null
};

const amenityReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constantType.LOAD_AMENITY_CONSTANT_START:
            return Object.assign({}, state, { isLoadingConstant: true });
        case constantType.LOAD_AMENITY_CONSTANT_SUCCESS:
            return Object.assign({}, state, {
                amenityList: action.amenityList,
                amenityName: action.amenityName,
                isLoadingConstant: false
            });
        case constantType.LOAD_AMENITY_CONSTANT_FAILURE:
            return Object.assign({}, state, { errorConstant: action.payload, isLoadingConstant: false });
        case constantType.LOAD_AMENITY_CONSTANT_END:
            return Object.assign({}, state, { isLoadingConstant: false });
        default:
            return state;
    }
};

export default amenityReducer;
