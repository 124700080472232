import React from "react";
import UnitModel from "./UnitModel";
import firebase from "../firebase";
import swal from "@sweetalert/with-react";
import i18n from "../../i18n";
import { NUM_OF_RECORDS_PER_PAGE, ONBOARDING } from "../Properties";

export default class UnitManagerImpl extends UnitModel {
    constructor(props) {
        super(props);
        this._init = this._init.bind(this);
        this._create = this._create.bind(this);
        this._view = this._view.bind(this);
        this.onEvent = this.onEvent.bind(this);
        this.isDuplicated = this.isDuplicated.bind(this);
        this._createAction = this._createAction.bind(this);
        this._updateAction = this._updateAction.bind(this);
        this._archieve = this._archieve.bind(this);
    }

    _init(units) {
        var output = [];
        if (units.length > 0) {
            units.forEach((unit) => {
                unit.createdDatetime = this.convertDisplayDate(unit.createdDatetime);
                output.push(Object.values(unit).splice(2));
            });
        }
        this.backups = units;
        let dataCount = output.length;
        let displayResult = [];
        /** size for number of records per page */
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        this.setState({ displayResult, dataCount, units, loading: false });
    }

    _create() {
        this.props.history.push({ pathname: this.state.onboarding ? "/unitForm" : "/admin/unitForm", state: { units: this.state.units, onboarding: this.state.onboarding } });
    }

    _view(index) {
        let units = this.state.units;
        units.forEach((unit) => {
            unit.index === index &&
                this.props.history.push({
                    pathname: this.state.onboarding ? "/unitForm" : "/admin/unitForm",
                    state: { unit, edit: true, units: this.state.units, onboarding: this.state.onboarding }
                });
        });
    }

    async onEvent(e) {
        e.preventDefault();
        this.setState({
            unitNumberErrorMessage: null,
            areaErrorMessage: null
        });
        if (this.state.unitNumber && this.state.areaId) {
            if (!this.isDuplicated()) {
                this.state.edit ? this._updateAction() : this._createAction();
            } else {
                swal(i18n.t("unit.input.duplicate.unit"), {
                    icon: "error"
                });
            }
        } else {
            this.setState({
                unitNumberErrorMessage: !this.state.unitNumber && i18n.t("unit.input.validation.unitNumber"),
                areaErrorMessage: !this.state.areaId && i18n.t("area.input.validation.locationName")
            });
        }
    }

    isDuplicated() {
        let isDuplicate = false;
        for (let i = 0; i < this.state.units.length; i++) {
            if (
                this.state.units[i].unitId !== this.state.unitId &&
                this.state.units[i].unitNumber.toUpperCase() === this.state.unitNumber.toUpperCase() &&
                this.state.units[i].areaId === this.state.areaId
            ) {
                isDuplicate = true;
                break;
            }
        }
        return isDuplicate;
    }

    _createAction() {
        let locationName;
        this.props.areas.forEach((e) => {
            this.state.areaId === e.id && (locationName = e.locationName);
        });

        swal({
            title: i18n.t("dialog.check.detail"),
            content: (
                <table>
                    <tbody>
                        <tr>
                            <td className="modelTitle">{i18n.t("unit.unitNumber")} :</td>
                            <td className="modelDesc">{this.state.unitNumber}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("area.locationName")} :</td>
                            <td className="modelDesc">{locationName}</td>
                        </tr>
                    </tbody>
                </table>
            ),
            icon: "warning",
            buttons: ["Cancel", "Create"]
        }).then((Confirm) => {
            if (Confirm) {
                this.scrollToTop();
                this.setState({ loading: true });
                let url = process.env.REACT_APP_API_URL + "/api/createUnit?cn=" + firebase.auth().currentUser.uid;
                fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        unitNumber: this.state.unitNumber,
                        areaId: this.state.areaId
                    })
                })
                    .then((response) => {
                        if (!response.ok) {
                            this.setState({ loading: false });
                            alert("Error", response.status.toString());
                            throw Error("Error " + response.status);
                        }
                        return response.json();
                    })
                    .then((responseJson) => {
                        this.setState({ loading: false });
                        swal(i18n.t(responseJson.status ? "common.create.success" : "technical.error"), {
                            icon: responseJson.status ? "success" : "error"
                        });
                        if (responseJson.status) {
                            this.props.history.push({
                                pathname: this.state.onboarding ? "/onboarding" : "/admin/unit",
                                state: {
                                    page: ONBOARDING.unitPage
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        swal(i18n.t("network.error"), {
                            icon: "error"
                        });
                        console.log(error);
                    });
            }
        });
    }

    _updateAction() {
        this.scrollToTop();
        this.setState({ loading: true });
        let url = process.env.REACT_APP_API_URL + "/api/updateUnit?cn=" + firebase.auth().currentUser.uid;
        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                unitId: this.state.unitId,
                unitNumber: this.state.unitNumber
            })
        })
            .then((response) => {
                if (!response.ok) {
                    this.setState({ loading: false });
                    alert("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                this.setState({ loading: false });
                swal(i18n.t(responseJson.status ? "common.update.success" : "technical.error"), {
                    icon: responseJson.status ? "success" : "error"
                });
                if (responseJson.status) {
                    this.props.history.push({
                        pathname: this.state.onboarding ? "/unitList" : "/admin/unit",
                        state: {
                            page: ONBOARDING.unitPage,
                            onboarding: this.state.onboarding
                        }
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                swal(i18n.t("network.error"), {
                    icon: "error"
                });
                console.log(error);
            });
    }

    _archieve(e) {
        e.preventDefault();
        this.setState({ loading2: true });
        let url =
            process.env.REACT_APP_API_URL +
            "/api/getRoom?cn=" +
            firebase.auth().currentUser.uid +
            "&unitId=" +
            this.state.unitId;

        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (!response.ok) {
                    this.setState({ loading: false });
                    alert("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                this.setState({ loading2: false });
                const numOfRoomRecord = responseJson.roomList.length;
                if (numOfRoomRecord === 0) {
                    swal(i18n.t("common.archieve.confirmation"), {
                        icon: "warning",
                        buttons: [i18n.t("common.no"), i18n.t("common.yes")]
                    }).then((confirm) => {
                        if (confirm) {
                            this.scrollToTop();
                            this.setState({ loading: true });
                            let url =
                                process.env.REACT_APP_API_URL + "/api/deleteUnit?cn=" + firebase.auth().currentUser.uid;

                            fetch(url, {
                                method: "POST",
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    unitId: this.state.unitId
                                })
                            })
                                .then((response) => {
                                    if (!response.ok) {
                                        this.setState({ loading: false });
                                        alert("Error", response.status.toString());
                                        throw Error("Error " + response.status);
                                    }
                                    return response.json();
                                })
                                .then((responseJson) => {
                                    this.setState({ loading: false });
                                    swal(
                                        i18n.t(responseJson.status ? "common.archieve.success" : "technical.error", {
                                            item: i18n.t("common.unit")
                                        }),
                                        {
                                            icon: responseJson.status ? "success" : "error"
                                        }
                                    );
                                    if (responseJson.status) {
                                        this.props.history.push({
                                            pathname: this.state.onboarding ? "/unitList" : "/admin/unit",
                                            state: {
                                                page: ONBOARDING.unitPage,
                                                onboarding: true,
                                            }
                                        });
                                    }
                                })
                                .catch((error) => {
                                    this.setState({ loading: false });
                                    swal(i18n.t("network.error"), {
                                        icon: "error"
                                    });
                                    console.log(error);
                                });
                        }
                    });
                } else {
                    swal({
                        text: i18n.t("unit.error.exist.room.record", {
                            number: numOfRoomRecord
                        }),
                        icon: "error"
                    });
                }
            });
    }
}
