import { actionType } from "../ActionType";

const INITIAL_STATE = {
    data: null,
    isLoading: false,
    error: null,
    status: null
};

const ownerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionType.LOAD_OWNER_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.LOAD_OWNER_SUCCESS:
            return Object.assign({}, state, { data: action.payload, isLoading: false });
        case actionType.LOAD_OWNER_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        case actionType.UPDATE_FIRSTTIMELOGIN_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.UPDATE_FIRSTTIMELOGIN_SUCCESS:
            return Object.assign({}, state, { status: action.payload, isLoading: false });
        case actionType.UPDATE_FIRSTTIMELOGIN_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        default:
            return state;
    }
};

export default ownerReducer;
