import firebase from "views/firebase";
import { actionType, constantType } from "../ActionType";

export const loadAreaConstant = () => {
    return (dispatch) => {
        dispatch({ type: constantType.LOAD_AREA_CONSTANT_START });
        let url = process.env.REACT_APP_API_URL + "/api/getArea?cn=" + firebase.auth().currentUser.uid;
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                let areas = [],
                    locationName = {};
                if (responseJson.status) {
                    responseJson.areaList.forEach((doc) => {
                        areas.push({
                            id: doc.areaId.toString(),
                            locationName: doc.locationName,
                            areaName: doc.areaName
                        });
                        locationName[doc.areaId] = doc.locationName;
                    });
                }
                dispatch({ type: constantType.LOAD_AREA_CONSTANT_SUCCESS, areas, locationName });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: constantType.LOAD_AREA_CONSTANT_FAILURE, payload: error });
            });
    };
};

export const loadAreas = () => {
    return (dispatch) => {
        dispatch({ type: actionType.LOAD_AREA_START });
        let url = process.env.REACT_APP_API_URL + "/api/getArea?cn=" + firebase.auth().currentUser.uid;
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem("token")
            }
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                dispatch({
                    type: actionType.LOAD_AREA_SUCCESS,
                    payload: responseJson.status ? responseJson.areaList : []
                });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: actionType.LOAD_AREA_FAILURE, payload: error });
            });
    };
};
