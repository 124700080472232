import React from "react";
import { NUM_OF_RECORDS_PER_PAGE, FIRST_PAGE, complaintStatusList } from "./Properties";
import * as moment from "moment";

export default class CommonManagerImpl extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0
        };
        this.handleChange = this.handleChange.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.handleAreaOptions = this.handleAreaOptions.bind(this);
        this._create = this._create.bind(this);
        this.searchLocationName = this.searchLocationName.bind(this);
        this.searchUnitNumber = this.searchUnitNumber.bind(this);
        this.searchRoomName = this.searchRoomName.bind(this);
        this.searchTenantName = this.searchTenantName.bind(this);
        this.searchTenantPhoneNumber = this.searchTenantPhoneNumber.bind(this);
        this.searchCreatedAt = this.searchCreatedAt.bind(this);
        this.searchAreaName = this.searchAreaName.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.filter = this.filter.bind(this);
        this.getKeyByValue = this.getKeyByValue.bind(this);
        this.convertDisplayDate = this.convertDisplayDate.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.dateRange = this.dateRange.bind(this);
        this.dateDiff = this.dateDiff.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.createUUID = this.createUUID.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.pad = this.pad.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleChange(e) {
        this.setState({ [e.target.id]: e.target.value });
    }

    handleNumberChange(e) {
        this.setState({ [e.target.id]: parseInt(e.target.value) });
    }

    fileChangedHandler(files) {
        if (files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = function () {
                var result = reader.result.substring(reader.result.indexOf(",") + 1);
                this.setState({ picture: reader.result, imageUri: result });
            }.bind(this);
        }
    }

    handleAreaOptions(changeEvent) {
        this.setState({
            areaId: changeEvent.target.value
        });
    }

    _create(pathname) {
        this.props.history.push({ pathname: pathname });
    }

    /**
     *
     * @param {*} e Required
     * @param {*} start Required
     * @param {*} count Optional
     */
    searchLocationName(e, start, count) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.locationName.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1)
            .forEach((result) =>
                output.push(
                    count
                        ? Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start, count)
                        : Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start)
                )
            );
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    /**
     *
     * @param {*} e Required
     * @param {*} start Required
     * @param {*} count Optional
     */
    searchUnitNumber(e, start, count) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.unitNumber.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1)
            .forEach((result) => {
                output.push(
                    count
                        ? Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start, count)
                        : Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start)
                );
            });
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    /**
     *
     * @param {*} e Required
     * @param {*} start Required
     * @param {*} count Optional
     */
    searchRoomName(e, start, count) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.roomName.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1)
            .forEach((result) => {
                output.push(
                    count
                        ? Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start, count)
                        : Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start)
                );
            });
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    /**
     *
     * @param {*} e Required
     * @param {*} start Required
     * @param {*} count Optional
     */
    searchCreatedAt(e, start, count) {
        let value = e.target.value ? new Date(e.target.value).customFormat("#DD#/#MM#/#YYYY#") : "";
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.createdAt.indexOf(value) > -1)
            .forEach((result) => {
                output.push(count ? Object.values(result).splice(start, count) : Object.values(result).splice(start));
            });
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    searchAreaName(e, start, count) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.areaName.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1)
            .forEach((result) =>
                output.push(count ? Object.values(result).splice(start, count) : Object.values(result).splice(start))
            );
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    /**
     *
     * @param {*} e Required
     * @param {*} start Required
     * @param {*} count Optional
     */
    searchTenantName(e, start, count) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.tenantName.toUpperCase().includes(e.target.value.toUpperCase()))
            .forEach((result) => {
                output.push(
                    count
                        ? Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start, count)
                        : Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start)
                );
            });
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    /**
     *
     * @param {*} e Required
     * @param {*} start Required
     * @param {*} count Optional
     */
    searchTenantPhoneNumber(e, start, count) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.tenantPhoneNumber.toUpperCase().includes(e.target.value.toUpperCase()))
            .forEach((result) => {
                output.push(
                    count
                        ? Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start, count)
                        : Object.values({
                              ...result,
                              complaintStatus: complaintStatusList[result.complaintStatus]
                          }).splice(start)
                );
            });
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }

    getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    filter(arr, criteria) {
        return arr.filter(function (obj) {
            return Object.keys(criteria).every(function (c) {
                return new RegExp(criteria[c]).test(obj[c]);
            });
        });
    }

    convertDisplayDate(value) {
        return new Date(value).customFormat("#DD#/#MM#/#YYYY#");
    }

    convertDate = (value) => {
        return new Date(value).customFormat("#YYYY#-#MM#-#DD#");
    };

    dateRange = (dateFrom, dateTo) => {
        let result = "";
        if (dateFrom && dateTo) {
            var ends = moment(dateTo);
            var starts = moment(dateFrom);

            var years = ends.diff(starts, "year");
            starts.add(years, "years");
            var months = ends.diff(starts, "months");
            starts.add(months, "months");
            var days = ends.diff(starts, "days");

            if (years !== 0) result += years + " years ";
            if (months !== 0) result += months + " months ";
            if (days !== 0) result += days + " days";
        }
        return result;
    };

    dateDiff = (date) => {
        const monthsAgo =
            new Date().getMonth() - date.getMonth() + 12 * (new Date().getFullYear() - date.getFullYear());
        const daysAgo = Math.round((new Date() - date) / (1000 * 60 * 60 * 24));

        if (monthsAgo >= 12) {
            return monthsAgo / 12 + " year(s) ago...";
        } else if (monthsAgo > 0 && monthsAgo <= 11) {
            return monthsAgo + " month(s) ago...";
        } else {
            return daysAgo <= 0 ? "Today" : daysAgo + " day(s) ago...";
        }
    };

    createUUID() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid;
    }

    scrollToTop() {
        document.getElementById("topMenu").scrollIntoView();
    }

    pad(num, size) {
        return ("0000" + num).substr(-size);
    }
}
