import firebase from "views/firebase";
import { actionType, constantType } from "../ActionType";

export const loadUnitConstant = () => {
    return (dispatch) => {
        dispatch({ type: constantType.LOAD_UNIT_CONSTANT_START });
        let url = process.env.REACT_APP_API_URL + "/api/getUnit?cn=" + firebase.auth().currentUser.uid;
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                let units = [],
                    unitNumber = {};
                if (responseJson.status) {
                    responseJson.unitList.forEach((doc) => {
                        units.push({
                            id: doc.unitId.toString(),
                            areaId: doc.areaId,
                            unitNumber: doc.unitNumber
                        });
                        unitNumber[doc.unitId] = doc.unitNumber;
                    });
                }
                dispatch({ type: constantType.LOAD_UNIT_CONSTANT_SUCCESS, units, unitNumber });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: constantType.LOAD_UNIT_CONSTANT_FAILURE, payload: error });
            });
    };
};

export const loadUnits = (areaId) => {
    return (dispatch) => {
        dispatch({ type: actionType.LOAD_UNIT_START });
        let url = process.env.REACT_APP_API_URL + "/api/getUnit?cn=" + firebase.auth().currentUser.uid + (areaId ? "&areaId=" + areaId : "");
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                dispatch({
                    type: actionType.LOAD_UNIT_SUCCESS,
                    payload: responseJson.status ? responseJson.unitList : []
                });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: actionType.LOAD_UNIT_FAILURE, payload: error });
            });
    };
};
