export const translation = {
    en: {
        "tenant.title": "Tenant List",
        "tenant.validation.searchCriteria": "Please choose search criteria.",
        "tenant.validation.searchValue": "Please enter search value.",
        "tenant.name": "Tenant Name",
        "tenant.identityNumber": "Identity Number",
        "tenant.emailAddress": "Email Address",
        "tenant.phoneNumber": "Phone Number",
        "tenant.agreementDate": "Agreement Effective Date",
        "tenant.nav.profile": "Profile",
        "tenant.nav.contractInfo": "Contract Info",
        "tenant.nav.contractFee": "Contract Fee",
        "tenant.nav.billing": "Invoice",
        "tenant.nav.payment": "Payment",
        "tenant.profile.gender": "Gender",
        "tenant.profile.dateOfBirth": "Date Of Birth",
        "tenant.contractInfo.agreementDate": "Agreement Date",
        "tenant.contractInfo.areaUnitRoom": "Area - Unit - Room",
        "tenant.contractInfo.parkingSlot": "Parking Slot",
        "tenant.contractInfo.rentalFee": "Rental Fee (Monthly)",
        "tenant.contractInfo.parkingFee": "Parking Fee (Monthy)",
        "tenant.contractInfo.duration": "Commencement & Expiry Date",
        "tenant.contractInfo.tenure": "Tenure",
        "tenant.contractFee.utilityName": "Utility Name",
        "tenant.contractFee.fee": "Fee (RM)",
        "tenant.contractFee.paid": "Paided Amount",
        "tenant.contractFee.outstanding": "Outstanding Amount",
        "tenant.contractFee.totalAmount": "Total Amount",
        "tenant.contractFee.status": "Payment Status",
        "tenant.billing.referenceNo": "Invoice No.",
        "tenant.billing.issueDate": "Issue Date",
        "tenant.billing.dueDate": "Due Date",
        "tenant.billing.amount": "Amount (RM)",
        "tenant.billing.paid": "Paid (RM)",
        "tenant.billing.status": "Status",
        "tenant.payment.detail": "Payment Details",
        "billing.addBtn": "Add Invoice",
        "billing.addBilling": "Add Utility",
        "billing.removeBilling": "Remove Utility",
        "billing.new": "New Invoice",
        "billing.detail": "Invoice Details",
        "billing.input.referenceNo": "Invoice Number",
        "billing.input.startDate": "Start Date",
        "billing.input.endDate": "End Date",
        "billing.input.utility": "Utility",
        "billing.input.amount": "Amount (RM)",
        "billing.input.utilities": "Utilities",
        "billing.input.totalAmount": "Total Amount (RM)",
        "billing.error.exist.payment": "This invoice had make payment by tenant."
    }
};
