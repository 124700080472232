import firebase from "views/firebase";
import { actionType, constantType } from "../ActionType";

export const loadParkingConstant = () => {
    return (dispatch) => {
        dispatch({ type: constantType.LOAD_PARKING_CONSTANT_START });
        let url = process.env.REACT_APP_API_URL + "/api/getParking?cn=" + firebase.auth().currentUser.uid;
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                let parkings = [],
                    parkingRefNo = {};
                if (responseJson.status) {
                    responseJson.parkingList.forEach((doc) => {
                        parkings.push({
                            id: doc.parkingId.toString(),
                            areaId: doc.areaId,
                            availability: doc.availability,
                            parkingUnitPrice: doc.parkingUnitPrice
                        });
                        parkingRefNo[doc.parkingId] = doc.parkingLotNumber;
                    });
                }
                dispatch({ type: constantType.LOAD_PARKING_CONSTANT_SUCCESS, parkings, parkingRefNo });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: constantType.LOAD_PARKING_CONSTANT_FAILURE, payload: error });
            });
    };
};

export const loadParkings = (areaId) => {
    return (dispatch) => {
        dispatch({ type: actionType.LOAD_PARKING_START });

        let url = process.env.REACT_APP_API_URL + "/api/getParking?cn=" + firebase.auth().currentUser.uid + (areaId ? "&areaId=" + areaId : "");
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                dispatch({
                    type: actionType.LOAD_PARKING_SUCCESS,
                    payload: responseJson.status ? responseJson.parkingList : []
                });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: actionType.LOAD_PARKING_FAILURE, payload: error });
            });
    };
};
