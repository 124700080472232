import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

export default function AppModalBox(props) {
    const { headerText, onConfirm, onCancel, message, showCancelBtn, okBtnText, cancelBtnText } = props;
    return (
        <Modal show={true} backdrop="static" keyboard={false}>
            <Modal.Header>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                {showCancelBtn && (
                    <Button variant="secondary" onClick={() => onCancel()}>
                        {cancelBtnText}
                    </Button>
                )}
                <Button variant="primary" onClick={() => onConfirm()}>
                    {okBtnText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

AppModalBox.propTypes = {
    headerText: PropTypes.string,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    message: PropTypes.string,
    showCancelBtn: PropTypes.bool,
    okBtnText: PropTypes.string,
    cancelBtnText: PropTypes.string
};
