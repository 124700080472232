/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/footerStyle.js";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";

const useStyles = makeStyles(styles);

export default function Footer(props) {
    const classes = useStyles();
    return (
        <footer>
            <div>
                <div className={classes.left} style={{ position: "absolute", bottom: "0.5px" }}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <a href="#home" className={classes.block} style={{ display: "flex", alignItems: "center" }}>
                                <FacebookIcon style={{color: "white" }} />
                                <span style={{color:"white"}}> Facebook </span>
                            </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a
                                href="#company"
                                className={classes.block}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <InstagramIcon style={{color: "white" }}/>
                                <span style={{color:"white"}}> Instagram </span>
                            </a>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a
                                href="#portfolio"
                                className={classes.block}
                                style={{ display: "flex", alignItems: "center" }}
                            >
                                <YouTubeIcon size="medium" style={{color: "white" }} />
                                <span style={{color:"white"}}> Youtube </span>
                            </a>
                        </ListItem>
                    </List>
                </div>
            </div>
        </footer>
    );
}
