export const translation = {
    en: {
        // "payment.title": "Outstanding Payment",
        "payment.title": "Payment",
        "payment.title.content": "Outstanding Payment ({{month}} {{year}})",
        "payment.accept.confirmation": "Are you sure to accept it ?",
        "payment.reject.confirmation": "Are you sure to reject it ?",
        "payment.update.success": "This payment status had been updated",
        "payment.no.record": "This billing doesn't have any payment",
        "payment.receiptNo": "Receipt No",
        "payment.paymentDate": "Last Payment Date",
        "payment.amount": "Amount (RM)",
        "payment.total.amount": "Total Amount (RM)",
        "payment.paymentMethod": "Payment Method",
        "payment.status": "Payment Status",
        "payment.invoiceNo": "Invoice No",
        "payment.refNo": "Invoice Number",
        "payment.tenantPhoneNo": "Tenant Phone No.",
        "payment.paidedAmount": "Paid Amount(RM)",

        //pop out payment
        "payment.paymentButton": "Proceed",
        "payment.detailButton": "View Receipt",
        "payment.totalRecord": "Total Number of Records : {{ totalRecord }}",
        "payment.backButton": "Back",
        "payment.popup.date": "Payment Date",
        "payment.popup.status": "Payment Status",
        "payment.popup.receiptNo": "Receipt No",
        "payment.popup.amount": "Amount (RM)",
        "payment.view.detail": "Receipt Details",
        "payment.rejectReason": "Reject Reason"
    }
};
