export const translation = {
    en: {
        "unit.title": "Unit List",
        "unit.unitNumber": "Unit Number",
        "unit.new": "New Unit",
        "unit.update": "Update Unit",
        "unit.input.validation.unitNumber": "Please enter valid unit number.",
        "unit.input.duplicate.unit": "Duplicated unit.",
        "unit.error.exist.room.record": "There are {{number}} records of room(s) that linked with this unit."
    }
};
