import React from "react";
import firebase from "firebase/app";
import swal from "@sweetalert/with-react";
import RegisterOwnerModel from "./RegisterOwnerModel";
import { genders } from "views/Properties";
import i18n from "../../i18n";
import moment from "moment";
import { idTypes } from "views/Properties";
const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL
};
const secondaryApp = firebase.initializeApp(config, "Secondary");

export default class RegisterOwnerManagerImpl extends RegisterOwnerModel {
    constructor(props) {
        super(props);
        this.validateEmailAddress = this.validateEmailAddress.bind(this);
        this.validateIdentityNumber = this.validateIdentityNumber.bind(this);
        this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        this.handleGenderOptions = this.handleGenderOptions.bind(this);
        this.handleIdTypeOptions = this.handleIdTypeOptions.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBlurChange = this.handleBlurChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.createPasswordLabel = this.createPasswordLabel.bind(this);
        this.handleIdentityNumber = this.handleIdentityNumber.bind(this);
        this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }

    handleIdTypeOptions(changeEvent) {
        this.setState({ primaryIdType: changeEvent.target.value, primaryIdNumber: "" });
    }

    handleGenderOptions(changeEvent) {
        this.setState({ gender: changeEvent.target.value });
    }

    validateEmailAddress() {
        var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.setState({
            emailAddressErrorMessage:
                this.state.emailAddress && !re.test(this.state.emailAddress)
                    ? i18n.t("register.validation.email.pattern")
                    : ""
        });
        return re.test(this.state.emailAddress);
    }

    validateIdentityNumber() {
        if (this.state.primaryIdNumber) {
            var re = /^(([[0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01]))-([0-9]{2})-([0-9]{4})$/;
            this.setState({
                identityNumberErrorMessage:
                    this.state.primaryIdNumber && !re.test(this.state.primaryIdNumber)
                        ? i18n.t("register.validation.identityNumber.pattern")
                        : ""
            })
            return re.test(this.state.primaryIdNumber);
        }
        this.setState({ identityNumberErrorMessage: "" })
        return false;
    }

    validatePhoneNumber() {
        var re = /^(\+?6?01)[0-46-9]-[0-9]{7,8}$/;
        this.setState({
            phoneNumberErrorMessage:
                this.state.phoneNumber && !re.test(this.state.phoneNumber)
                    ? i18n.t("register.validation.phoneNumber.pattern")
                    : ""
        })
        return re.test(this.state.phoneNumber);
    }

    handleLogin(e) {
        this.props.history.push({ pathname: "/login" });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (
            this.state.emailAddress &&
            this.state.password &&
            this.state.password.length >= 6 &&
            this.state.name &&
            this.state.primaryIdNumber &&
            this.state.gender &&
            this.state.phoneNumber &&
            this.validateEmailAddress() &&
            this.validateIdentityNumber() &&
            this.validatePhoneNumber()
        ) {
            if (!this.state.phoneNumber.startsWith("6")) {
                this.setState({ phoneNumber: "6".concat(this.state.phoneNumber) });
            }
            swal({
                title: i18n.t("dialog.check.detail"),
                content: (
                    <table style={{ fontSize: 10 }}>
                        <tbody>
                            <tr>
                                <td className="modelTitle">{i18n.t("register.emailAddress")} :</td>
                                <td className="modelDesc">{this.state.emailAddress}</td>
                            </tr>
                            <tr>
                                <td className="modelTitle">{i18n.t("register.name")} :</td>
                                <td className="modelDesc">{this.state.name}</td>
                            </tr>
                            <tr>
                                <td className="modelTitle">{i18n.t("register.identityType")} :</td>
                                <td className="modelDesc">{idTypes[this.state.primaryIdType]}</td>
                            </tr>
                            <tr>
                                <td className="modelTitle">{i18n.t("register.identityNumber")} :</td>
                                <td className="modelDesc">{this.state.primaryIdNumber}</td>
                            </tr>
                            <tr>
                                <td className="modelTitle">{i18n.t("register.dateOfBirth")} :</td>
                                <td className="modelDesc">{this.state.dateOfBirth}</td>
                            </tr>
                            <tr>
                                <td className="modelTitle">{i18n.t("register.gender")} :</td>
                                <td className="modelDesc">{genders[this.state.gender]}</td>
                            </tr>
                            <tr>
                                <td className="modelTitle">{i18n.t("register.phoneNumber")} :</td>
                                <td className="modelDesc">{!this.state.phoneNumber.startsWith("6") ?
                                    "6".concat(this.state.phoneNumber) : this.state.phoneNumber}</td>
                            </tr>
                        </tbody>
                    </table>
                ),
                icon: "warning",
                buttons: [i18n.t("common.cancel"), i18n.t("register.button")]
            }).then((Confirm) => {
                if (Confirm) {
                    this.setState({ loading: true });
                    secondaryApp
                        .auth()
                        .createUserWithEmailAndPassword(this.state.emailAddress, this.state.password)
                        .then((response) => {
                            response.user.sendEmailVerification();
                            secondaryApp.auth().signOut();
                            return fetch(process.env.REACT_APP_API_URL + "/api/createUser ", {
                                method: "POST",
                                dataType: "json",
                                mode: "cors",
                                origin: true,
                                headers: {
                                    Accept: "application/json",
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    cn: response.user.uid,
                                    userType: "owner",
                                    token: "",
                                    name: this.state.name,
                                    primaryIdType: this.state.primaryIdType,
                                    primaryIdNumber: this.state.primaryIdNumber,
                                    gender: this.state.gender,
                                    emailAddress: this.state.emailAddress,
                                    dateOfBirth: this.state.dateOfBirth,
                                    phoneNumber: this.state.phoneNumber
                                })
                            });
                        })
                        .then((response) => {
                            if (!response.ok) {
                                this.setState({ loading: false });
                                console.log("Error: " + response.status.toString());
                            }
                        })
                        .then(() => {
                            this.setState({
                                loading: false,
                                emailAddress: "",
                                password: "",
                                name: "",
                                primaryIdType: "",
                                primaryIdNumber: "",
                                dateOfBirth: "",
                                gender: "",
                                phoneNumber: "",
                                errorChecking: false
                            });
                            swal(i18n.t("register.successful"), {
                                icon: "success"
                            }).then((confirm) => {
                                if (confirm) {
                                    window.location.pathname = "/login";
                                }
                            });
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            swal(error.message, {
                                icon: "error"
                            });
                        });
                }
            });
        } else {
            this.setState({ errorChecking: true });
            swal(i18n.t("register.validation.field"), {
                icon: "warning"
            });
        }
    }

    handleIdentityNumber(e) {
        if (e.target.value.length >= 6) {
            var subyear = e.target.value.substring(0, 2);
            var month = e.target.value.substring(2, 4);
            var day = e.target.value.substring(4, 6);
            var year = moment(subyear + "-" + month + "-" + day, "YY-MM-DD").format("YYYY");
            this.setState({ dateOfBirth: year !== "Invalid date" ? year + "-" + month + "-" + day : "" });
        } else {
            this.setState({ dateOfBirth: "" });
        }
        if ((e.target.value.length === 6 && this.state.primaryIdNumber.length === 5) || 
            (e.target.value.length === 9 && this.state.primaryIdNumber.length === 8)) {
            this.setState({ [e.target.id]: e.target.value.concat("-") });
        } else {
            this.setState({ [e.target.id]: e.target.value });
        }
    }

    handlePhoneNumber(e) {
        if (e.target.value.length === 3 && this.state.phoneNumber.length === 2) {
            this.setState({ [e.target.id]: e.target.value.concat("-") });
        } else {
            this.setState({ [e.target.id]: e.target.value });
        }
    }

    handlePasswordChange(e) {
        this.setState({ password_message: false, [e.target.id]: e.target.value });
    }

    handleBlurChange() {
        this.setState({ password_message: true });
    }

    createPasswordLabel(result) {
        switch (result.score) {
            case 0:
                return "Weak";
            case 1:
                return "Weak";
            case 2:
                return "Fair";
            case 3:
                return "Good";
            case 4:
                return "Strong";
            default:
                return "Weak";
        }
    }
}
