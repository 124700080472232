import React from "react";
import Loader from "react-loader-spinner";
import i18n from "../../i18n";
import OriButton from "@material-ui/core/Button";
import Button from "components/CustomButtons/Button.js";
// import CssBaseline from "@material-ui/core/CssBaseline";  // for side image full screen
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import InputLabel from "@material-ui/core/InputLabel";
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import RegisterOwnerManagerImpl from "./RegisterOwnerManagerImpl";
import { genders, idTypes } from "views/Properties";
import Background from "../../assets/img/background.jpg";
import "./PasswordStrengthMeter.css";
import zxcvbn from "zxcvbn";

export default class RegisterOwner extends RegisterOwnerManagerImpl {
    render() {
        const testedResult = zxcvbn(this.state.password);
        const isIcNumberType = this.state.primaryIdType && this.state.primaryIdType === "IN";
        return (
            <Grid container component="main" style={{ height: "100%" }}>
                <div className="darkoverlay">
                    <div className="background">
                        <img src={Background} alt="Logo" />
                    </div>
                </div>
                <Card className="form">
                    <CardHeader color="yellow">
                        <h4
                            style={{
                                fontSize: "28px",
                                fontWeight: "bold",
                                fontFamily: "Franklin Gothic",
                                textAlign: "center"
                            }}
                        >
                            {i18n.t("register.title")}
                        </h4>
                    </CardHeader>
                    <CardBody style={{ padding: "0 50px" }}>
                        <TextField
                            id="emailAddress"
                            value={this.state.emailAddress}
                            style={{
                                marginTop: "40px",
                                marginBottom: "0px"
                            }}
                            error={(this.state.errorChecking && !this.state.emailAddress) ||
                                (this.state.emailAddress && this.state.emailAddressErrorMessage)}
                            onBlur={() => {
                                this.validateEmailAddress();
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={i18n.t("register.emailAddress")}
                            type="email"
                            onChange={this.handleChange}
                        />
                        <br />
                        {this.state.emailAddressErrorMessage && (
                            <label style={{ fontSize: 15, color: "red", paddingTop: 0, position: "absolute" }}>
                                {this.state.emailAddressErrorMessage}
                            </label>
                        )}
                        <TextField
                            id="password"
                            value={this.state.password}
                            style={{ marginBottom: "0px", marginTop: "30px" }}
                            error={(this.state.errorChecking && !this.state.password) ||
                                (this.state.password && this.state.password.length < 6)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={i18n.t("register.password")}
                            type="password"
                            onChange={this.handlePasswordChange}
                            onBlur={this.handleBlurChange}
                        />
                        <div className="password-strength-meter">
                            <progress
                                className={`password-strength-meter-progress strength-${this.createPasswordLabel(
                                    testedResult
                                )}`}
                                value={testedResult.score}
                                max="4"
                            />
                            <br />
                            <label
                                id="message2"
                                className="password-strength-meter-label"
                                style={{ color: "#000000", fontSize: 10, position: "absolute" }}
                            >
                                {this.state.password &&
                                    (this.state.password_message && this.state.password.length < 6 ? (
                                        <label style={{ fontSize: 15, color: "red" }}>
                                            {i18n.t("register.passwordLength")}
                                        </label>
                                    ) : (
                                        <span style={{ fontSize: 15 }}>
                                            <strong style={{ color: "#000000" }}>Password strength:</strong>{" "}
                                            {this.createPasswordLabel(testedResult)}
                                        </span>
                                    ))}
                            </label>
                        </div>
                        <TextField
                            id="name"
                            value={this.state.name}
                            style={{ marginTop: "30px" }}
                            error={this.state.errorChecking && !this.state.name}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={i18n.t("register.name")}
                            onChange={this.handleChange}
                        />
                        <Grid container spacing={2} style={{ marginTop: "0px" }}>
                            <Grid item xs={6}>
                                <FormControl
                                    variant="outlined"
                                    style={{ marginBottom: "0px", marginTop: "0px", width: "100%" }}
                                    error={this.state.errorChecking && !this.state.primaryIdType}
                                >
                                    <InputLabel>{i18n.t("register.identityType")}</InputLabel>
                                    <Select
                                        defaultValue={this.state.primaryIdType}
                                        value={this.state.primaryIdType}
                                        onChange={this.handleIdTypeOptions}
                                    >
                                        {idTypes !== null &&
                                            Object.entries(idTypes).map((idType) => (
                                                <MenuItem key={idType[1]} value={idType[0]}>
                                                    {idType[1]}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    id="primaryIdNumber"
                                    value={this.state.primaryIdNumber}
                                    disabled={!this.state.primaryIdType}
                                    inputProps={{ maxLength: isIcNumberType ? 14 : 50 }}
                                    style={{ marginBottom: "0px", marginTop: "0px" }}
                                    error={this.state.errorChecking && !this.state.primaryIdNumber}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={this.state.primaryIdType ? idTypes[this.state.primaryIdType] : "Identity Number"}
                                    placeholder={isIcNumberType ? "e.g. XXXXXX-XX-XXXX" : ""}
                                    onChange={isIcNumberType ? this.handleIdentityNumber : this.handleChange}
                                    onBlur={() => {
                                        isIcNumberType && this.validateIdentityNumber();
                                    }}
                                />
                                <br />
                                {isIcNumberType && this.state.identityNumberErrorMessage && (
                                    <label style={{ fontSize: 15, color: "red", paddingTop: 0, position: "absolute" }}>
                                        {this.state.identityNumberErrorMessage}
                                    </label>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: "0px" }}>
                            <Grid item xs={6}>
                                <TextField
                                    id="dateOfBirth"
                                    type={isIcNumberType ? 'text' : 'date'}
                                    disabled={isIcNumberType}
                                    error={!isIcNumberType && this.state.errorChecking && !this.state.dateOfBirth}
                                    style={{ marginBottom: "0px" }}
                                    value={this.state.dateOfBirth}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={i18n.t("register.dateOfBirth")}
                                    onChange={this.handleChange}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl
                                    variant="outlined"
                                    style={{
                                        width: "100%",
                                        marginTop: 16
                                    }}
                                    error={this.state.errorChecking && !this.state.gender}
                                >
                                    <InputLabel>{i18n.t("owner.profile.gender")}</InputLabel>
                                    <Select
                                        defaultValue={this.state.gender}
                                        value={this.state.gender}
                                        onChange={this.handleGenderOptions}
                                    >
                                        {genders !== null &&
                                            Object.entries(genders).map((gender) => (
                                                <MenuItem key={gender[1]} value={gender[0]}>
                                                    {gender[1]}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <TextField
                            id="phoneNumber"
                            value={this.state.phoneNumber}
                            inputProps={{ maxLength: 12 }}
                            style={{ marginBottom: "0px" }}
                            error={(this.state.errorChecking && !this.state.phoneNumber) ||
                                (this.state.phoneNumber && this.state.phoneNumberErrorMessage)}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={i18n.t("register.phoneNumber")}
                            placeholder="e.g. XXX-XXXXXXX"
                            onChange={this.handlePhoneNumber}
                            onBlur={() => {
                                this.validatePhoneNumber();
                            }}
                        />
                        <br />
                        {this.state.phoneNumberErrorMessage && (
                            <label style={{ fontSize: 15, color: "red", paddingTop: 0, position: "absolute" }}>
                                {this.state.phoneNumberErrorMessage}
                            </label>
                        )}
                        <div style={{ paddingLeft: "100px", paddingRight: "100px", textAlign: "center", marginTop: "15px" }}>
                            <Button
                                style={{ marginTop: "15px", fontSize: "20px" }}
                                color="blue"
                                size="medium"
                                onClick={this.handleSubmit}
                                fullWidth
                            >
                                {i18n.t("register.button")}
                            </Button>
                            <div style={{ margintop: "10px" }}>
                                <Loader
                                    visible={this.state.loading}
                                    type="ThreeDots"
                                    color="#0099ff"
                                    height={50}
                                    width={100}
                                />
                            </div>
                        </div>
                        <Box mt={2} style={{ textAlign: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <h5>Already have an Account ? </h5>
                                <div style={{ textAlign: "center" }}>
                                    <OriButton
                                        style={{ marginTop: "-14px", fontSize: "20px" }}
                                        color="primary"
                                        size="medium"
                                        onClick={this.handleLogin}
                                    >
                                        {i18n.t("register.login")}
                                    </OriButton>
                                </div>
                            </div>

                            <div variant="body2" color="textSecondary" align="center" style={{ marginTop: "10px" }}>
                                {i18n.t("common.copyright", {
                                    brandName: i18n.t("common.brandName"),
                                    year: new Date().getFullYear()
                                })}
                            </div>
                        </Box>
                    </CardBody>
                </Card>
                {/* <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    style={{
                        backgroundImage: "url(https://source.unsplash.com/random)",
                        backgroundPosition: "center",
                        objectFit: "cover",
                        height: window.innerHeight
                    }}
                /> */}
            </Grid>
        );
    }
}
