import React from "react";
// @material-ui/core components
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Loader from "react-loader-spinner";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import UnitManagerImpl from "./UnitManagerImpl";
import { Icon } from "@material-ui/core";
import i18n from "../../i18n";
import * as areaActions from "../../redux/actions/AreaAction";
import { connect } from "react-redux";
import { FIRST_PAGE, ONBOARDING } from "../Properties";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Header from "../Onboarding/Header.js";
import Footer from "../Onboarding/Footer.js";
import Background from "assets/img/onboardingBackground.jpg";

class UnitForm extends UnitManagerImpl {
    componentDidMount() {
        if (!this.state.edit) {
            this.props.loadAreaConstant();
            this.intervalID = setInterval(() => {
                const { isAreaConstantCacheLoading } = this.props;
                this.setState({ loading: isAreaConstantCacheLoading });
                if (!isAreaConstantCacheLoading) {
                    clearInterval(this.intervalID);
                }
            }, 100);
        } else {
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <div className={this.state.onboarding ? "darkoverlay" : ""}>
                {this.state.onboarding && (
                    <div className="background">
                        <img src={Background} alt="Logo" />
                    </div>
                )}
                {this.state.onboarding && <Header />}
                <div
                    className="form"
                    style={{
                        maxWidth: this.state.onboarding ? "1200px" : "none",
                        margin: this.state.onboarding ? "60px auto" : "none"
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)"
                        }}
                    >
                        <Loader
                            visible={this.state.loading}
                            type="TailSpin" //Puff
                            color="#0099ff"
                            height={100}
                            width={100}
                        />
                    </div>
                    {!this.state.loading && (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card profile>
                                    <CardHeader color="yellow">
                                        <h4
                                            style={{
                                                fontSize: "28px",
                                                fontWeight: "bold",
                                                fontFamily: "Franklin Gothic",
                                                marginLeft: "20px",
                                                marginTop: "10px"
                                            }}
                                        >
                                            {i18n.t(this.state.edit ? "unit.update" : "unit.new")}
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <CustomInput
                                                    labelText={i18n.t("unit.unitNumber")}
                                                    id="unitNumber"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    value={this.state.unitNumber}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            this.setState({ unitNumberErrorMessage: null });
                                                        }
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                {this.state.unitNumberErrorMessage && (
                                                    <span style={{ color: "red" }}>
                                                        {this.state.unitNumberErrorMessage}
                                                    </span>
                                                )}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                {this.state.edit ? (
                                                    <CustomInput
                                                        labelText={
                                                            <div>
                                                                {i18n.t("area.locationName")}{" "}
                                                                <Tooltip title={i18n.t("tooltip.block.edit.message")}>
                                                                    <HelpIcon style={{ color: "#000000" }} />
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                        id="locationName"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        style={{ color: "#000000" }}
                                                        value={this.state.locationName}
                                                        disabled={true}
                                                    />
                                                ) : (
                                                    <FormControl
                                                        style={{
                                                            width: "100%",
                                                            paddingBottom: "10px",
                                                            margin: "27px 0 0 0",
                                                            position: "relative",
                                                            verticalAlign: "unset"
                                                        }}
                                                    >
                                                        <InputLabel htmlFor="grouped-select">
                                                            {i18n.t("area.locationName")}
                                                        </InputLabel>
                                                        <Select
                                                            defaultValue={this.state.areaID}
                                                            value={this.state.areaID}
                                                            input={
                                                                <Input
                                                                    id="grouped-select"
                                                                    onChange={(e) => {
                                                                        if (e.target.value) {
                                                                            this.setState({ areaErrorMessage: null });
                                                                        }
                                                                        this.handleAreaOptions(e);
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            {this.props.areas !== null ? (
                                                                this.props.areas.map((area) => (
                                                                    <MenuItem value={area.id}>
                                                                        {area.locationName}
                                                                    </MenuItem>
                                                                ))
                                                            ) : (
                                                                <MenuItem value={""}>
                                                                    {i18n.t("common.loading")}
                                                                </MenuItem>
                                                            )}
                                                        </Select>
                                                        {this.state.areaErrorMessage && (
                                                            <span style={{ color: "red", marginTop: "10px" }}>
                                                                {this.state.areaErrorMessage}
                                                            </span>
                                                        )}
                                                    </FormControl>
                                                )}
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            color="white"
                                            onClick={() => {
                                                this.props.history.push({
                                                    pathname:
                                                        this.state.units.length > 0 && this.state.onboarding
                                                            ? "/unitList"
                                                            : this.state.onboarding
                                                            ? "/onboarding"
                                                            : "/admin/unit",
                                                    state: {
                                                        activePage: this.state.edit
                                                            ? this.state.activePage
                                                            : FIRST_PAGE,
                                                        page: ONBOARDING.unitPage,
                                                        onboarding: this.state.onboarding
                                                    }
                                                });
                                            }}
                                        >
                                            <ArrowBackIcon style={{ fontSize: 18, marginRight: 10 }} />
                                            <strong>{i18n.t("common.back")}</strong>
                                        </Button>
                                        <div>
                                            {this.state.edit && (
                                                <Button color={"danger"} onClick={this._archieve}>
                                                    {i18n.t("common.archieve")}&nbsp;&nbsp;&nbsp;
                                                    {this.state.loading2 ? (
                                                        <i
                                                            style={{ color: "#ffffff", fontSize: 20 }}
                                                            class="fa fa-spinner fa-spin"
                                                        ></i>
                                                    ) : (
                                                        <Icon style={{ fontSize: 18 }}>delete_forever</Icon>
                                                    )}
                                                </Button>
                                            )}
                                            &nbsp;&nbsp;&nbsp;
                                            <Button color={this.state.edit ? "blue" : "success"} onClick={this.onEvent}>
                                                <strong>
                                                    {" "}
                                                    {i18n.t(this.state.edit ? "common.update" : "common.create")}
                                                </strong>
                                                <NoteAddIcon style={{ fontSize: 18, marginLeft: 10 }} />
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    )}
                </div>
                {this.state.onboarding && <Footer />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAreaConstantCacheLoading: state.areaReducer.isLoadingConstant,
    areas: state.areaReducer.areas
});

const mapDispatchToProps = (dispatch) => ({
    loadAreaConstant: () => dispatch(areaActions.loadAreaConstant())
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitForm);
