import React, { Component } from "react";
import IdleTimer from "react-idle-timer";

const debounce = 250;

interface Props {
    onTimeout?: () => void;
    onReset?: () => void;
}

interface State {
    renderIdleTimer: boolean;
    idleTimeout: number;
}

class AppIdleTimer extends Component<Props, State> {
    idleTimer: IdleTimer;

    constructor(props: Props) {
        super(props);
        this.idleTimer = null;
        this.state = {
            renderIdleTimer: false,
            idleTimeout: 0
        };

        setInterval(() => {
            if (this.idleTimer) {
                console.debug("remainingTime: ", this.idleTimer.getRemainingTime());
            } else {
                console.debug("idleTime not initialized");
            }
        }, 1000);
    }

    onAction(e) {
        console.debug("onAction: user did something", e);
        this.props.onReset && this.props.onReset();
    }

    onActive(e) {
        console.debug("onActive: user is active", e);
        console.debug("onActive: time remaining", this.idleTimer.getRemainingTime());
    }

    onIdle(e) {
        console.debug("onIdle: user is idle", e);
        console.debug("onIdle: last active", this.idleTimer.getLastActiveTime());
        this.props.onTimeout && this.props.onTimeout();
    }

    startTimer(timeout) {
        this.setState({ renderIdleTimer: true, idleTimeout: timeout });
    }

    resetTimer() {
        this.idleTimer.reset();
        this.props.onReset && this.props.onReset();
    }

    stopTimer() {
        this.setState({ renderIdleTimer: false, idleTimeout: 0 });
        this.idleTimer = null;
    }

    render() {
        return (
            <>
                {this.state.renderIdleTimer && (
                    <IdleTimer
                        ref={ref => {
                            this.idleTimer = ref;
                        }}
                        events={["keydown", "mousemove", "mousedown"]}
                        element={document}
                        onActive={e => this.onActive(e)}
                        onIdle={e => this.onIdle(e)}
                        onAction={e => this.onAction(e)}
                        debounce={debounce}
                        timeout={this.state.idleTimeout}
                        stopOnIdle={true}
                    />
                )}
            </>
        );
    }
}

export default AppIdleTimer;
