export const translation = {
    en: {
        "parking.title": "Parking List",
        "parking.parkingLotNumber": "Parking Lot Number",
        "parking.parkingType": "Parking Type",
        "parking.parkingUnitPrice": "Parking Unit Price (RM)",
        "parking.new": "New Parking",
        "parking.update": "Update Parking",
        "parking.input.unitParking": "Unit Parking",
        "parking.input.managementParking": "Management Parking",
        "parking.input.duplicate.parking": "Duplicated parking.",
        "parking.error.exist.contract.ongoing": "This parking slot has contract is ongoing."
    }
};
