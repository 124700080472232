import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import dashboardReducer from "../reducers/DashboardReducer";
import ownerReducer from "../reducers/OwnerReducer";
import areaReducer from "../reducers/AreaReducer";
import unitReducer from "../reducers/UnitReducer";
import roomReducer from "../reducers/RoomReducer";
import parkingReducer from "../reducers/ParkingReducer";
import utilityReducer from "../reducers/UtilityReducer";
import amenityReducer from "../reducers/AmenityReducer";
import tenantReducer from "../reducers/TenantReducer";
import requestReducer from "../reducers/RequestReducer";
import billingReducer from "../reducers/BillingReducer";

const rootReducer = combineReducers({
    dashboardReducer,
    ownerReducer,
    areaReducer,
    unitReducer,
    roomReducer,
    parkingReducer,
    utilityReducer,
    amenityReducer,
    tenantReducer,
    requestReducer,
    billingReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
