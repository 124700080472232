import { constantType } from "../ActionType";

const INITIAL_STATE = {
    utilities: [],
    utilityName: {},
    isLoadingConstant: false,
    errorConstant: null
};

const utilityReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constantType.LOAD_UTILITY_CONSTANT_START:
            return Object.assign({}, state, { isLoadingConstant: true });
        case constantType.LOAD_UTILITY_CONSTANT_SUCCESS:
            return Object.assign({}, state, {
                utilities: action.utilities,
                utilityName: action.utilityName,
                isLoadingConstant: false
            });
        case constantType.LOAD_UTILITY_CONSTANT_FAILURE:
            return Object.assign({}, state, { errorConstant: action.payload, isLoadingConstant: false });
        case constantType.LOAD_UTILITY_CONSTANT_END:
            return Object.assign({}, state, { isLoadingConstant: false });
        default:
            return state;
    }
};

export default utilityReducer;
