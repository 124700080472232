import React from "react";
import Button2 from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import "./LoginAs.css";
import CommonManagerImpl from "views/CommonManagerImpl";
import Background from "../../assets/img/background.jpg";
// import House from "./house.png"; future used
import Bed from "./bed.png";
import UnderConstruction from "./UnderConstruction.png";
import i18n from "../../i18n";

export default class Login extends CommonManagerImpl {
    constructor(props) {
        super(props);
        this.state = {};

        this.handleRoomSubmit = this.handleRoomSubmit.bind(this);
    }
    handleRoomSubmit(e) {
        this.props.history.push({ pathname: "/login" });
    }

    render() {
        return (
            <div>
                <Grid container style={{ height: "60%" }}>
                    <CssBaseline />
                    <div className="darkoverlay">
                        <div className="background">
                            <img src={Background} alt="Logo" />
                        </div>
                    </div>

                    <Card
                        className="form"
                        style={{
                            maxWidth: "450px",
                            minHeight: "450px",
                            marginTop: "150px"
                        }}
                    >
                        <CardHeader color="yellow">
                            <h4
                                style={{
                                    fontSize: "28px",
                                    fontWeight: "bold",
                                    fontFamily: "Franklin Gothic",
                                    textAlign: "center"
                                }}
                            >
                                {i18n.t("loginAs.rentByRoom")}
                            </h4>
                        </CardHeader>
                        <div className="img-wrapper">
                            <img
                                style={{
                                    maxWidth: 240,
                                    maxHeight: 240,
                                    minWidth: 120,
                                    minHeight: 120,
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "50%",
                                    marginTop: "40px"
                                }}
                                onClick={this.handleRoomSubmit}
                                className="hover-zoom"
                                src={Bed || require("../img/file.png")}
                                alt={"Bed"}
                            />
                        </div>
                        <div
                            className="loginAsButton"
                            style={{ paddingLeft: "90px", paddingRight: "90px", textAlign: "center" }}
                        >
                            <Button2
                                style={{ fontSize: "20px", marginTop: "40px" }}
                                variant="outlined"
                                color="secondary"
                                size="medium"
                                onClick={this.handleRoomSubmit}
                                fullWidth
                            >
                                {i18n.t("loginAs.enter")}
                            </Button2>
                        </div>
                    </Card>

                    <Card
                        className="form"
                        style={{
                            maxWidth: "450px",
                            minHeight: "450px",
                            marginTop: "150px"
                        }}
                    >
                        <CardHeader color="yellow">
                            <h4
                                style={{
                                    fontSize: "28px",
                                    fontWeight: "bold",
                                    fontFamily: "Franklin Gothic",
                                    textAlign: "center"
                                }}
                            >
                                {i18n.t("loginAs.rentByUnit")}
                            </h4>
                        </CardHeader>
                        <div className="img-wrapper">
                            <img
                                style={{
                                    maxWidth: 350,
                                    maxHeight: 240,
                                    minWidth: 350,
                                    minHeight: 240,
                                    display: "block",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "50%",
                                    marginTop: "40px"
                                }}
                                className="hover-zoom"
                                src={UnderConstruction || require("../img/file.png")}
                                alt={"UnderConstruction"}
                            />
                        </div>
                        <h2
                            style={{
                                fontSize: "28px",
                                fontWeight: "bold",
                                fontFamily: "Franklin Gothic",
                                textAlign: "center"
                            }}
                        >
                            Coming Soon
                        </h2>
                    </Card>
                </Grid>
            </div>
        );
    }
}
