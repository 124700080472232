export const translation = {
    en: {
        "area.title": "Location List",
        "area.locationName": "Location Name",
        "area.areaName": "Area Name",
        "area.postcode": "Postcode",
        "area.city": "City",
        "area.state": "State",
        "area.new": "New Location",
        "area.update": "Update Location",
        "area.input.locationName": "Location Name (Condo/House Area etc)",
        "area.address": "Address",
        "area.input.validation.locationName": "Please enter valid location name.",
        "area.input.duplicate.locationName": "Duplicated locations.",
        "area.input.validation.address": "Please enter valid address.",
        "area.input.validation.postcode": "Please enter valid postcode.",
        "area.input.validation.area": "Please select valid area.",
        "area.input.validation.city": "Please select valid city.",
        "area.input.validation.state": "Please select valid state.",
        "area.error.exist.unit.record": "There are {{number}} records of unit(s) linked with this location.",
        "area.error.exist.parking.record": "There are {{number}} records of parking(s) linked with this location.",
        "area.error.exist.unit.parking.record": "There are {{number}} records of unit(s) and {{number2}} records of parking(s) linked with this location."
    }
};
