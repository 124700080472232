import { actionType } from "../ActionType";

const INITIAL_STATE = {
    numOfInvoice: null,
    isLoading: false,
    error: null
};

const billingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionType.LOAD_BILLING_COUNT_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.LOAD_BILLING_COUNT_SUCCESS:
            return Object.assign({}, state, { numOfInvoice: action.payload, isLoading: false });
        case actionType.LOAD_BILLING_COUNT_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        default:
            return state;
    }
};

export default billingReducer;
