import i18n from "i18next";
import { translation as LoginAsI18n } from "views/LoginAs/i18n";
import { translation as LoginI18n } from "views/Login/i18n";
import { translation as OnboardingI18n } from "views/Onboarding/i18n";
import { translation as DashboardI18n } from "views/Dashboard/i18n";
import { translation as OwnerProfileI18n } from "views/OwnerProfile/i18n";
import { translation as AreaI18n } from "views/Area/i18n";
import { translation as UnitI18n } from "views/Unit/i18n";
import { translation as RoomI18n } from "views/Room/i18n";
import { translation as ParkingI18n } from "views/Parking/i18n";
import { translation as TenantI18n } from "views/Tenant/i18n";
import { translation as ComplaintI18n } from "views/Complaint/i18n";
import { translation as PaymentI18n } from "views/Payment/i18n";
import { translation as RequestI18n } from "views/Request/i18n";
import { translation as RegisterOwnerI18n } from "views/RegisterOwner/i18n";
import { translation as RegisterTenantI18n } from "views/RegisterTenant/i18n";

const rootI18n = {
    en: {
        "common.location": "location",
        "common.unit": "unit",
        "common.room": "room",
        "common.parking": "parking",
        "common.billing": "billing",
        "common.availability": "Availability",
        "common.create": "Create",
        "common.create.success": "Poof! New Record has been created!",
        "common.update": "Update",
        "common.update.success": "Poof! Record has been updated!",
        "common.search": "Search",
        "common.search.fail": "No result found",
        "common.no.record": "No record found",
        "common.yes": "Yes",
        "common.no": "No",
        "common.back": "Back",
        "common.cancel": "Cancel",
        "common.close": "Close",
        "common.confirm": "Confirm",
        "common.reset": "Reset",
        "common.clear": "Clear",
        "common.loading": "Loading...",
        "common.archieve": "Archieve",
        "common.archieve.confirmation": "Are you sure to archieve ?",
        "common.archieve.success": "Poof! This {{item}} has been archived!",
        "common.number": "No.",
        "common.createdAt": "Created At",
        "common.referenceNo": "Reference Number",
        "loading.message": "Tenant Management System is Loading ...",
        "dialog.check.detail": "Please check the details",
        "dialog.confirmation": "Are you sure ?",
        "tooltip.block.edit.message": "Not allowed to edit",
        "network.error": "Something wrong with the network!",
        "network.login.error": "Something went wrong, We are working on getting this fixed as soon as we can. You may try your request again at a later time.",
        "technical.error": "Technical Error",
        "error.missing.field": "Please enter all details.",
        "error.field.required": "*this field is required",
        "dropdown.all": "All",
        "dropdown.searchCriteria": "Search Criteria",
        "status.complete": "Complete",
        "status.halfComplete": "Half Complete",
        "status.incomplete": "Incomplete",
        "common.copyright": "Copyright © {{ brandName }} {{ year }}.",
        "common.brandName": "rentsmartprop",
        "common.ok": "OK",
        "common.warning": "Warning",
        "common.confirmation": "Confirmation",
        "listener.countdownmsg": "Due to inactivity, your session will expire in {{countdownseconds}} seconds.",
        "listener.session.expired": "Your session expired, please login again",
        "verify.email": "Please verify your email address before login to Tenant Management System.",
        "verify.resentEmail": "Resent Verification Email",
        "resent.email.message.success": "Email had been sent. Please check your email inbox."
    }
};

i18n.init({
    resources: {
        en: {
            translations: Object.assign(
                {},
                rootI18n.en,
                LoginI18n.en,
                LoginAsI18n.en,
                OnboardingI18n.en,
                DashboardI18n.en,
                OwnerProfileI18n.en,
                AreaI18n.en,
                UnitI18n.en,
                RoomI18n.en,
                ParkingI18n.en,
                TenantI18n.en,
                ComplaintI18n.en,
                PaymentI18n.en,
                RequestI18n.en,
                RegisterOwnerI18n.en,
                RegisterTenantI18n.en
            )
        }
    },
    fallbackLng: "en",
    debug: false,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true
    }
});

export default i18n;
