import { actionType, constantType } from "../ActionType";

const INITIAL_STATE = {
    roomList: [],
    rooms: [],
    roomName: {},
    roomPrice: {},
    isLoading: false,
    error: null,
    isLoadingConstant: false,
    errorConstant: null
};

const roomReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constantType.LOAD_ROOM_CONSTANT_START:
            return Object.assign({}, state, { isLoadingConstant: true });
        case constantType.LOAD_ROOM_CONSTANT_SUCCESS:
            return Object.assign({}, state, {
                rooms: action.rooms,
                roomName: action.roomName,
                roomPrice: action.roomPrice,
                isLoadingConstant: false
            });
        case constantType.LOAD_ROOM_CONSTANT_FAILURE:
            return Object.assign({}, state, { errorConstant: action.payload, isLoadingConstant: false });
        case actionType.LOAD_ROOM_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.LOAD_ROOM_SUCCESS:
            return Object.assign({}, state, { roomList: action.payload, isLoading: false });
        case actionType.LOAD_ROOM_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        default:
            return state;
    }
};

export default roomReducer;
