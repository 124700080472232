export const translation = {
    en: {
        "room.title": "Room List",
        "room.roomName": "Room Name",
        "room.roomNameOpt": "Room Name (put 'ALL' for unit rent)",
        "room.rentalPrice": "Rental (RM)",
        "room.availableDate": "Available Date",
        "room.uploadImage": "Upload Image",
        "room.rentalUnitPrice": "Rental Unit Price (RM)",
        "room.input.validation.image": "Please select an image.",
        "room.input.validation.roomName": "Please enter valid room name.",
        "room.input.validation.rentalUnitPrice": "Please enter valid rental unit price.",
        "room.input.validation.locationName": "Please select valid location name.",
        "room.input.validation.unitNumber": "Please select valid unit number.",
        "room.input.duplicate.room": "Duplicated room.",
        "room.input.selected.location.emptyUnit":
            "Selected location doesn't link with any unit. Please create it before proceed to create room.",
        "room.error.exist.contract.ongoing": "This room has contract is ongoing.",
        "room.input.amenity.description": "Description of Amenity in this room (Optional)",
        "room.amenity.description": "Amenity Description"
    }
};
