export const genders = {
    M: "Male",
    F: "Female"
};

export const idTypes = {
    IN: "IC Number",
    PP: "Passport"
}

export const requestStatus = {
    "01": "Pending",
    "02": "Pending Contract Creation",
    "03": "Created",
    "04": "Rejected"
};

export const contractStatus = {
    "01": "Pending",
    "02": "Completed"
};

export const complaintStatusList = {
    "01": "Open",
    "02": "Work In Progress",
    "03": "Pending Verification",
    "04": "Completed"
};

export const paymentStatus = {
    "01": "Pending Verification",
    "02": "Approved",
    "03": "Rejected"
};

export const paymentTypes = {
    BL: "Billing",
    CF: "Contract Fee",
    DD: "Direct Debit"
};

export const roomStatus = {
    "01": "Not Available",
    "02": "Available",
    "03": "Available Soon"
};

export const parkingStatus = {
    "01": "Not Available",
    "02": "Available"
};

export const parkingTypeName = {
    UP: "Unit",
    MP: "Management"
};

export const searchCriteria = [
    {
        key: "01",
        value: "Name"
    },
    {
        key: "02",
        value: "Identity Number"
    }
];

export const transactionMonth = [
    {
        key: 0,
        value: "January"
    },
    {
        key: 1,
        value: "February"
    },
    {
        key: 2,
        value: "March"
    },
    {
        key: 3,
        value: "April"
    },
    {
        key: 4,
        value: "May"
    },
    {
        key: 5,
        value: "Jun"
    },
    {
        key: 6,
        value: "July"
    },
    {
        key: 7,
        value: "August"
    },
    {
        key: 8,
        value: "September"
    },
    {
        key: 9,
        value: "October"
    },
    {
        key: 10,
        value: "November"
    },
    {
        key: 11,
        value: "December"
    }
];

export const transactionYear = [
    {
        key: 2019,
        value: "2019"
    },
    {
        key: 2020,
        value: "2020"
    },
    {
        key: 2021,
        value: "2021"
    }
];

export const transactionStatus = {
    "01": "Not Complete",
    "02": "Pending Verification",
    "03": "Completed"
};

export const FIRST_PAGE = 1;
export const NUM_OF_RECORDS_PER_PAGE = 5;

export const utilityType = {
    "01": "deposit",
    "02": "billing",
    "03": "billing-deposit",
    "04": "direct-debit",
    "05": "additional",
};

// var searchCriteriaStatus = {
//     "01":"Name",
//     "02":"Identity Number",
//     "03":"Gender",
//     "04":"Date Of Birth",
//     "05":"Email Address",
//     "06":"Phone Number",
// }

export const paymentMethod = {
    "01": "Cash",
    "02": "Bank"
};

export const complaintMode = {
    // "01" : "reject_maintenance",
    // "01" : "reopen_maintenance",
    "02" : "wip_maintenance",
    "03" : "pending_verify",
    "04" : "accept_maintenance"
}

export const CONSTANT = {
    idleTime: 5 * 60 * 1000,
    displayIdleTime: 60,
};

export const ONBOARDING = {
    welcomePage: 1,
    tncPage: 2,
    areaPage: 3,
    unitPage: 4,
    roomPage: 5,
    lastPage: 6
}

export const ONBOARDING_TOTAL_PAGES = 7;