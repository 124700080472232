import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Loader from "react-loader-spinner";
import AreaManagerImpl from "./AreaManagerImpl";
import { Icon } from "@material-ui/core";
import i18n from "../../i18n";
import { FIRST_PAGE, ONBOARDING } from "../Properties";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import Header from "../Onboarding/Header.js";
import Footer from "../Onboarding/Footer.js";
import Background from "assets/img/onboardingBackground.jpg";
import * as unitActions from "../../redux/actions/UnitAction";
import * as parkingActions from "../../redux/actions/ParkingAction";
import { connect } from "react-redux";

class AreaForm extends AreaManagerImpl {
    componentDidMount() {
        if (this.state.postcode) {
            this.setState({ loading: true });
            this.getAreaOptions();
        }
    }

    render() {
        return (
            <div className={this.state.onboarding ? "darkoverlay" : ""}>
                {this.state.onboarding && (
                    <div className="background">
                        <img src={Background} alt="Logo" />
                    </div>
                )}
                {this.state.onboarding && <Header />}
                <div
                    className="form"
                    style={{
                        maxWidth: this.state.onboarding ? "1200px" : "none",
                        margin: this.state.onboarding ? "60px auto" : "none"
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)"
                        }}
                    >
                        <Loader
                            visible={this.state.loading}
                            type="TailSpin" //Puff
                            color="#0099ff"
                            height={100}
                            width={100}
                        />
                    </div>
                    {!this.state.loading && (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card profile>
                                    <CardHeader color="yellow">
                                        <h3
                                            style={{
                                                fontSize: "28px",
                                                fontWeight: "bold",
                                                fontFamily: "Franklin Gothic",
                                                marginLeft: "20px",
                                                marginTop: "10px"
                                            }}
                                        >
                                            {i18n.t(this.state.edit ? "area.update" : "area.new")}
                                        </h3>
                                    </CardHeader>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <CustomInput
                                                    labelText={i18n.t("area.input.locationName")}
                                                    id="locationName"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    value={this.state.locationName}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            this.setState({ locationNameErrorMessage: null });
                                                        }
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                {this.state.locationNameErrorMessage && (
                                                    <span style={{ color: "red" }}>
                                                        {this.state.locationNameErrorMessage}
                                                    </span>
                                                )}
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <CustomInput
                                                    labelText={i18n.t("area.address")}
                                                    id="address"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    value={this.state.address}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            this.setState({ addressErrorMessage: null });
                                                        }
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                {this.state.addressErrorMessage && (
                                                    <span style={{ color: "red" }}>
                                                        {this.state.addressErrorMessage}
                                                    </span>
                                                )}
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={9}>
                                                <CustomInput
                                                    labelText={i18n.t("area.postcode")}
                                                    id="postcode"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    type={"number"}
                                                    value={this.state.postcode}
                                                    disabled={this.state.searchLoading}
                                                    onChange={(e) => {
                                                        if (e.target.value) {
                                                            this.setState({ postcodeErrorMessage: null });
                                                        }
                                                        this.handleChange(e);
                                                    }}
                                                />
                                                {this.state.postcodeErrorMessage && (
                                                    <span style={{ color: "red" }}>
                                                        {this.state.postcodeErrorMessage}
                                                    </span>
                                                )}
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={3}>
                                                <div style={{ marginTop: 30 }}>
                                                    {this.state.searchLoading ? (
                                                        <Loader
                                                            visible={this.state.searchLoading}
                                                            type="ThreeDots" //Puff
                                                            color="#0099ff"
                                                            height={50}
                                                            width={50}
                                                        />
                                                    ) : (
                                                        <Button color="info" onClick={this.getAreaOptions}>
                                                            <strong>{i18n.t("common.search")}</strong>
                                                            <SearchIcon style={{ fontSize: 18, marginLeft: 10 }} />
                                                        </Button>
                                                    )}
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: 35,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <select
                                                        className="form-control searchInput"
                                                        id="areaName"
                                                        onChange={(e) => {
                                                            if (e.target.value) {
                                                                this.setState({ areaErrorMessage: null });
                                                            }
                                                            this.handleChange(e);
                                                        }}
                                                        value={this.state.areaName}
                                                        disabled={this.state.inputDisabled}
                                                    >
                                                        <option key="" value="">
                                                            {i18n.t("area.areaName")}
                                                        </option>
                                                        {this.state.areaOptions.map((x) => (
                                                            <option key={x} value={x}>
                                                                {x}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {this.state.areaErrorMessage && (
                                                        <span style={{ color: "red" }}>
                                                            {this.state.areaErrorMessage}
                                                        </span>
                                                    )}
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: 35,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <select
                                                        className="form-control searchInput"
                                                        id="city"
                                                        onChange={(e) => {
                                                            if (e.target.value) {
                                                                this.setState({ cityErrorMessage: null });
                                                            }
                                                            this.handleChange(e);
                                                        }}
                                                        value={this.state.city}
                                                        disabled={this.state.inputDisabled}
                                                    >
                                                        <option key="" value="">
                                                            {i18n.t("area.city")}
                                                        </option>
                                                        {this.state.cityOptions.map((x) => (
                                                            <option key={x} value={x}>
                                                                {x}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {this.state.cityErrorMessage && (
                                                        <span style={{ color: "red" }}>
                                                            {this.state.cityErrorMessage}
                                                        </span>
                                                    )}
                                                </div>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={4}>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: 35,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <select
                                                        className="form-control searchInput"
                                                        id="state"
                                                        onChange={(e) => {
                                                            if (e.target.value) {
                                                                this.setState({ stateErrorMessage: null });
                                                            }
                                                            this.handleChange(e);
                                                        }}
                                                        value={this.state.state}
                                                        disabled={this.state.inputDisabled}
                                                    >
                                                        <option key="" value="">
                                                            {i18n.t("area.state")}
                                                        </option>
                                                        {this.state.stateOptions.map((x) => (
                                                            <option key={x} value={x}>
                                                                {x}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {this.state.stateErrorMessage && (
                                                        <span style={{ color: "red" }}>
                                                            {this.state.stateErrorMessage}
                                                        </span>
                                                    )}
                                                </div>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                    <CardFooter>
                                        <Button
                                            color="white"
                                            onClick={() => {
                                                this.props.history.push({
                                                    pathname:
                                                        this.state.areas.length > 0 && this.state.onboarding
                                                            ? "/areaList"
                                                            : this.state.onboarding
                                                            ? "/onboarding"
                                                            : "/admin/area",
                                                    state: {
                                                        activePage: this.state.edit
                                                            ? this.state.activePage
                                                            : FIRST_PAGE,
                                                        page: ONBOARDING.areaPage,
                                                        onboarding: this.state.onboarding
                                                    }
                                                });
                                            }}
                                        >
                                            <ArrowBackIcon style={{ fontSize: 18, marginRight: 10 }} />
                                            <strong>{i18n.t("common.back")}</strong>
                                        </Button>
                                        <div>
                                            {this.state.edit && (
                                                <Button color={"danger"} onClick={this._archieve}>
                                                    {i18n.t("common.archieve")}&nbsp;&nbsp;&nbsp;
                                                    {this.state.loading2 ? (
                                                        <i
                                                            style={{ color: "#ffffff", fontSize: 20 }}
                                                            class="fa fa-spinner fa-spin"
                                                        ></i>
                                                    ) : (
                                                        <Icon style={{ fontSize: 18 }}>delete_forever</Icon>
                                                    )}
                                                </Button>
                                            )}
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                color={this.state.edit ? "blue" : "success"}
                                                onClick={this.onEvent}
                                                disabled={this.state.inputDisabled}
                                            >
                                                {i18n.t(this.state.edit ? "common.update" : "common.create")}
                                                &nbsp;&nbsp;&nbsp;
                                                <Icon style={{ fontSize: 18 }}>save</Icon>
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    )}
                </div>
                {this.state.onboarding && <Footer />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isUnitCacheLoading: state.unitReducer.isLoading,
    unitList: state.unitReducer.unitList,
    isParkingCacheLoading: state.parkingReducer.isLoading,
    parkingList: state.parkingReducer.parkingList
});

const mapDispatchToProps = (dispatch) => ({
    loadUnits: (areaId) => dispatch(unitActions.loadUnits(areaId)),
    loadParkings: (areaId) => dispatch(parkingActions.loadParkings(areaId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AreaForm);
