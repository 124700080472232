import { actionType, constantType } from "../ActionType";

const INITIAL_STATE = {
    areaList: [],
    areas: [],
    locationName: {},
    isLoading: false,
    error: null,
    isLoadingConstant: false,
    errorConstant: null
};

const areaReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constantType.LOAD_AREA_CONSTANT_START:
            return Object.assign({}, state, { isLoadingConstant: true });
        case constantType.LOAD_AREA_CONSTANT_SUCCESS:
            return Object.assign({}, state, {
                areas: action.areas,
                locationName: action.locationName,
                isLoadingConstant: false
            });
        case constantType.LOAD_AREA_CONSTANT_FAILURE:
            return Object.assign({}, state, { errorConstant: action.payload, isLoadingConstant: false });
        case actionType.LOAD_AREA_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.LOAD_AREA_SUCCESS:
            return Object.assign({}, state, { areaList: action.payload, isLoading: false });
        case actionType.LOAD_AREA_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        default:
            return state;
    }
};

export default areaReducer;
