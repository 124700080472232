import { actionType, constantType } from "../ActionType";

const INITIAL_STATE = {
    unitList: [],
    units: [],
    unitNumber: {},
    isLoading: false,
    error: null,
    isLoadingConstant: false,
    errorConstant: null
};

const unitReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constantType.LOAD_UNIT_CONSTANT_START:
            return Object.assign({}, state, { isLoadingConstant: true });
        case constantType.LOAD_UNIT_CONSTANT_SUCCESS:
            return Object.assign({}, state, {
                units: action.units,
                unitNumber: action.unitNumber,
                isLoadingConstant: false
            });
        case constantType.LOAD_UNIT_CONSTANT_FAILURE:
            return Object.assign({}, state, { errorConstant: action.payload, isLoadingConstant: false });
        case actionType.LOAD_UNIT_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.LOAD_UNIT_SUCCESS:
            return Object.assign({}, state, { unitList: action.payload, isLoading: false });
        case actionType.LOAD_UNIT_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        default:
            return state;
    }
};

export default unitReducer;
