import CommonManagerImpl from "views/CommonManagerImpl";
import { FIRST_PAGE } from "../Properties";

export default class RoomModel extends CommonManagerImpl {
    constructor(props) {
        super(props);
        this.state = {
            rooms: this.props.location.state?.rooms ?? null,
            displayResult: null,
            edit: this.props.location.state?.edit ?? false,
            roomId: this.props.location.state?.room?.roomId ?? "",
            roomName: this.props.location.state?.room?.roomName ?? "",
            locationName: this.props.location.state?.room?.locationName ?? "",
            unitNumber: this.props.location.state?.room?.unitNumber ?? "",
            areaId: this.props.location.state?.room?.areaId ?? "",
            unitId: this.props.location.state?.room?.unitId ?? "",
            oldPrice: this.props.location.state?.room?.rentalUnitPrice ?? "",
            rentalUnitPrice: this.props.location.state?.room?.rentalUnitPrice ?? "",
            availability: this.props.location.state?.room?.availability ?? "",
            amenityDescription: this.props.location.state?.room?.amenityDescription ?? "",
            roomPicture: this.props.location.state?.room?.roomPicture ?? null,
            roomPictureUUID: this.props.location.state?.room?.roomPictureUUID ?? "",
            loading: false,
            picture: null,
            imageUri: null,
            disabledOption: true,
            roomNameErrorMessage: null,
            rentalUnitPriceErrorMessage: null,
            locationErrorMessage: null,
            unitErrorMessage: null,
            imageErrorMessage: null,
            units: [],
            activePage: this.props.location.state?.activePage ?? FIRST_PAGE,
            dataCount: 0,
            onboarding: this.props.location.state?.onboarding ?? false
        };
    }
    backups = [];
}
