export const translation = {
    en: {
        "request.title": "Request List",
        "request.name": "Name",
        "request.date": "Request Date",
        "request.status": "Status",
        "request.approve": "Approve",
        "request.reject": "Reject",
        "request.contract.detail": "Contract Details",
        "request.commencementDate": "Commencement Date",
        "request.expiryDate": "Expiry Date",
        "request.parkingSlot": "Parking Slot",
        "request.carPlateNo": "Car Plate No.",
        "request.add": "Add {{item}}",
        "request.remove": "Remove {{item}}",
        "request.depositList": "Deposit List",
        "request.depositDetail": "Deposit Details",
        "request.amount": "Amount (RM)",
        "request.fee": "Fee",
        "request.uploadAgreement": "Upload Contract Form (Accept pdf format only)",
        "request.downloadAgreement": "Download Contract Form",
        "request.validation.date": "Please select valid date.",
        "request.validation.parking": "Please select valid parking.",
        "request.validation.carPlate": "Please enter valid car plate.",
        "request.validation.utility": "Please select valid deposit detail.",
        "request.validation.amount": "Please enter valid amount.",
        "request.validation.agreement": "Please select a tenancy agreement document",
        "request.deposit": "Deposit",
        "request.totalAmount": "Total Amount (RM)",
        "request.dialog.reject.validation": "Are you sure to reject this request?",
        "request.status.updated": "Request Form's status had been updated successfully",
        "request.parkingFee": "Parking Fee Monthy",
        "request.area.emptyUnit": "Selected area doesn't have any unit",
        "request.unit.emptyRoom": "Selected unit doesn't have any available room",
        "request.validation.identityNumber": "Please enter identity number.",
        "request.validation.tenantName": "Please enter tenant name",
        "request.validation.tenantIdentityNumber": "Please enter tenant identity number",
        "request.validation.tenantEmailAddress": "Please enter tenant email address",
        "request.validation.tenantDateOfBirth": "Please enter tenant date of birth",
        "request.validation.tenantPhoneNumber": "Please enter tenant phone number",
        "request.validation.tenantGender": "Please enter tenant gender",
        "request.validation.locationErrorMessage": "Please choose an area",
        "request.validation.unitErrorMessage": "Please choose a unit",
        "request.validation.roomErrorMessage": "Please choose a room",
        "request.createRoom": "create room",
        "request.remark": "Please use the search function to check whether the tenant existed in our system, else new tenant will be created."
    }
};
