import CommonManagerImpl from "views/CommonManagerImpl";
import { FIRST_PAGE } from "../Properties";

export default class AreaModel extends CommonManagerImpl {
    constructor(props) {
        super(props);
        this.state = {
            areas: this.props.location.state?.areas?? null,
            displayResult: null,
            loading: false,
            loading2: false,
            edit: this.props.location.state?.edit ?? false,
            areaId: this.props.location.state?.area?.areaId ?? "",
            locationName: this.props.location.state?.area?.locationName ?? "",
            areaName: this.props.location.state?.area?.areaName ?? "",
            address: this.props.location.state?.area?.address ?? "",
            postcode: this.props.location.state?.area?.postcode ?? "",
            city: this.props.location.state?.area?.city ?? "",
            state: this.props.location.state?.area?.state ?? "",
            area: this.props.location.state?.area?? null,
            areaOptions: [],
            cityOptions: [],
            stateOptions: [],
            searchLoading: false,
            inputDisabled: true,
            locationNameErrorMessage: null,
            addressErrorMessage: null,
            postcodeErrorMessage: null,
            areaErrorMessage: null,
            cityErrorMessage: null,
            stateErrorMessage: null,
            activePage: this.props.location.state?.activePage ?? FIRST_PAGE,
            dataCount: 0,
            onboarding: this.props.location.state?.onboarding ?? false
        };
    }
    stateList = [];
    cityList = [];
    backups = [];
}
