import React from "react";
import firebase from "../firebase";
import swal from "@sweetalert/with-react";
import RoomModel from "./RoomModel";
import { ONBOARDING, roomStatus, NUM_OF_RECORDS_PER_PAGE, FIRST_PAGE } from "../Properties";
import i18n from "../../i18n";

export default class RoomManagerImpl extends RoomModel {
    constructor(props) {
        super(props);
        this._init = this._init.bind(this);
        this.searchPrice = this.searchPrice.bind(this);
        this._create = this._create.bind(this);
        this._view = this._view.bind(this);
        this._loadImage = this._loadImage.bind(this);
        this.onEvent = this.onEvent.bind(this);
        this.isDuplicated = this.isDuplicated.bind(this);
        this._createAction = this._createAction.bind(this);
        this._updateAction = this._updateAction.bind(this);
        this.handleArea = this.handleArea.bind(this);
        this.handleUnitOptions = this.handleUnitOptions.bind(this);
        this._archieve = this._archieve.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
    }

    _init(rooms) {
        if (rooms.length > 0) {
            rooms.forEach((room) => {
                room.availableDate = room.availableDate != null ? this.convertDisplayDate(room.availableDate) : "-";
                room.availability = roomStatus[room.availability];
            });
        }
        var output = rooms;
        if (output.length > 0 && this.state.availability !== "") {
            output = rooms.filter((x) => x.availability === this.state.availability);
        }
        if (rooms.length > 0) {
            output = output.map((room) => Object.values(room).splice(2, 8));
        }
        this.backups = rooms;
        let dataCount = output.length;
        let displayResult = [];
        /** size for number of records per page */
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        this.setState({ displayResult, dataCount, rooms, loading: false });
    }

    searchPrice(e, start, count) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.rentalUnitPrice.toString().indexOf(e.target.value) > -1)
            .forEach((result) => {
                output.push(count ? Object.values(result).splice(start, count) : Object.values(result).splice(start));
            });
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    searchAvailability(e, start, count) {
        let searchInput = this.getKeyByValue(roomStatus, e.target.value) || "";
        let output = [];
        var displayResult = [];
        this.backups
            .filter((x) => this.getKeyByValue(roomStatus, x.availability).indexOf(searchInput) > -1)
            .forEach((result) => {
                output.push(count ? Object.values(result).splice(start, count) : Object.values(result).splice(start));
            });
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    searchAvailableDate(e, start, count) {
        let value = e.target.value ? new Date(e.target.value).customFormat("#DD#/#MM#/#YYYY#") : "";
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.availableDate.indexOf(value) > -1)
            .forEach((result) => {
                output.push(count ? Object.values(result).splice(start, count) : Object.values(result).splice(start));
            });
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    _create() {
        this.props.history.push({ pathname: this.state.onboarding ? "/roomForm" : "/admin/roomForm", state: { rooms: this.state.rooms, onboarding: this.state.onboarding } });
    }

    _view(index) {
        let rooms = this.state.rooms;
        rooms.forEach((room) => {
            if (room.index === index) {
                room.availability = this.getKeyByValue(roomStatus, room.availability);
                this.props.history.push({
                    pathname: this.state.onboarding ? "/roomForm" : "/admin/roomForm",
                    state: { room, edit: true, rooms: this.state.rooms, onboarding: this.state.onboarding }
                });
            }
        });
    }

    _loadImage() {
        this.setState({ loading: false, picture: this.state.roomPicture });
    }

    fileChangedHandler(files) {
        if (files.length > 0) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = function () {
                var result = reader.result.substring(reader.result.indexOf(",") + 1);
                this.setState({ roomPicture: reader.result, imageUri: result });
            }.bind(this);
        }
    }

    onEvent(e) {
        e.preventDefault();
        this.setState({
            roomNameErrorMessage: null,
            rentalUnitPriceErrorMessage: null,
            locationErrorMessage: null,
            unitErrorMessage: null,
            imageErrorMessage: null
        });
        const isMandatoryAreaField = this.state.edit || this.state.areaId;
        const isMandatoryUnitField = this.state.edit || this.state.unitId;
        const isMandatoryUploadImg = this.state.edit || this.state.roomPicture;
        if (
            this.state.roomName &&
            this.state.rentalUnitPrice &&
            isMandatoryAreaField &&
            isMandatoryUnitField &&
            isMandatoryUploadImg
        ) {
            if (!this.isDuplicated()) {
                this.state.edit ? this._updateAction() : this._createAction();
            } else {
                swal(i18n.t("room.input.duplicate.room"), {
                    icon: "error"
                });
            }
        } else {
            this.setState({
                roomNameErrorMessage: !this.state.roomName && i18n.t("room.input.validation.roomName"),
                rentalUnitPriceErrorMessage:
                    !this.state.rentalUnitPrice && i18n.t("room.input.validation.rentalUnitPrice"),
                locationErrorMessage: !this.state.areaId && i18n.t("room.input.validation.locationName"),
                unitErrorMessage: !this.state.unitId && i18n.t("room.input.validation.unitNumber"),
                imageErrorMessage: !this.state.imageUri && i18n.t("room.input.validation.image")
            });
        }
    }

    isDuplicated() {
        let isDuplicate = false;
        for (let i = 0; i < this.state.rooms.length; i++) {
            if (
                this.state.rooms[i].roomId !== this.state.roomId &&
                this.state.rooms[i].roomName.toUpperCase() === this.state.roomName.toUpperCase() &&
                this.state.rooms[i].unitId === this.state.unitId
            ) {
                isDuplicate = true;
                break;
            }
        }
        return isDuplicate;
    }

    async _createAction() {
        let locationName;
        this.props.areas.find((e) => this.state.areaId === e.id && (locationName = e.locationName));

        let unitNumber;
        this.props.units.find((e) => this.state.unitId === e.id && (unitNumber = e.unitNumber));

        swal({
            title: i18n.t("dialog.check.detail"),
            content: (
                <table style={{ fontSize: 10 }}>
                    <tbody>
                        <tr>
                            <td className="modelTitle">{i18n.t("room.roomName")} :</td>
                            <td className="modelDesc">{this.state.roomName}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("room.rentalUnitPrice")} :</td>
                            <td className="modelDesc">{this.state.rentalUnitPrice}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("area.locationName")} :</td>
                            <td className="modelDesc">{locationName}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("unit.unitNumber")} :</td>
                            <td className="modelDesc">{unitNumber}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("room.amenity.description")} :</td>
                            <td className="modelTextbox">{this.state.amenityDescription}</td>
                        </tr>
                    </tbody>
                </table>
            ),
            icon: "warning",
            buttons: ["Cancel", "Create"]
        }).then((Confirm) => {
            if (Confirm) {
                this.scrollToTop();
                this.setState({ loading: true });
                const uuid = this.createUUID();

                let url = process.env.REACT_APP_API_URL + "/api/createRoom?cn=" + firebase.auth().currentUser.uid;
                fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        unitId: this.state.unitId,
                        roomName: this.state.roomName.toUpperCase() === "ALL" ? this.state.roomName.toUpperCase() : this.state.roomName,
                        amenityDescription: this.state.amenityDescription,
                        roomPicture: uuid,
                        rentalUnitPrice: this.state.rentalUnitPrice
                    })
                })
                    .then((response) => {
                        if (!response.ok) {
                            this.setState({ loading: false });
                            alert("Error", response.status.toString());
                            throw Error("Error " + response.status);
                        }
                        return response.json();
                    })
                    .then((responseJson) => {
                        if (responseJson.status) {
                            var base64EncodedImageString = this.state.imageUri,
                                metadata = { contentType: "image/jpeg" },
                                imageBuffer = new Buffer(base64EncodedImageString, "base64");
                            firebase.storage().ref(`rooms/${uuid}`).put(imageBuffer, metadata);
                        }
                        return responseJson;
                    })
                    .then((responseJson) => {
                        this.setState({ loading: false });
                        swal(i18n.t(responseJson.status ? "common.create.success" : "technical.error"), {
                            icon: responseJson.status ? "success" : "error"
                        });
                        if (responseJson.status) {
                            this.props.history.push({
                                pathname: this.state.onboarding ? "/onboarding" : "/admin/room",
                                state: {
                                    page: ONBOARDING.roomPage
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        swal(i18n.t("network.error"), {
                            icon: "error"
                        });
                        console.log(error);
                    });
            }
        });
    }

    async _updateAction() {
        this.scrollToTop();
        this.setState({ loading: true });
        let url = process.env.REACT_APP_API_URL + "/api/updateRoom?cn=" + firebase.auth().currentUser.uid;
        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                roomId: this.state.roomId,
                roomName: this.state.roomName.toUpperCase() === "ALL" ? this.state.roomName.toUpperCase() : this.state.roomName,
                amenityDescription: this.state.amenityDescription,
                rentalUnitPrice: this.state.rentalUnitPrice
            })
        })
            .then((response) => {
                if (!response.ok) {
                    this.setState({ loading: false });
                    alert("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                if (this.state.imageUri != null) {
                    var base64EncodedImageString = this.state.imageUri,
                        metadata = { contentType: "image/jpeg" },
                        imageBuffer = new Buffer(base64EncodedImageString, "base64");
                    firebase.storage().ref(`rooms/${this.state.roomPictureUUID}`).put(imageBuffer, metadata);
                }
                return responseJson;
            })
            .then((responseJson) => {
                this.setState({ loading: false });
                swal(i18n.t(responseJson.status ? "common.update.success" : "technical.error"), {
                    icon: responseJson.status ? "success" : "error"
                });
                if (responseJson.status) {
                    this.props.history.push({
                        pathname: this.state.onboarding ? "/roomList" : "/admin/room",
                        state: {
                            page: ONBOARDING.roomPage,
                            onboarding: this.state.onboarding
                        }
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                swal(i18n.t("network.error"), {
                    icon: "error"
                });
                console.log(error);
            });
    }

    handleArea(changeEvent) {
        var units = [];
        if (changeEvent.target.value !== "-") {
            this.props.units.forEach((unit) => {
                if (unit.areaId === parseInt(changeEvent.target.value)) {
                    units.push(unit);
                }
            });
            if (units.length !== 0) {
                this.setState({ disabledOption: false });
            } else {
                this.setState({ disabledOption: true });
                swal(i18n.t("room.input.selected.location.emptyUnit"), {
                    icon: "warning"
                });
            }
        }

        this.setState({
            units,
            areaId: changeEvent.target.value,
            unitId: "",
            locationErrorMessage: null
        });
    }

    handleUnitOptions(changeEvent) {
        this.setState({
            unitId: changeEvent.target.value,
            unitErrorMessage: null
        });
    }

    _archieve(e) {
        e.preventDefault();
        if (this.state.availability === "02") {
            swal(i18n.t("common.archieve.confirmation"), {
                icon: "warning",
                buttons: [i18n.t("common.no"), i18n.t("common.yes")]
            }).then((confirm) => {
                if (confirm) {
                    this.scrollToTop();
                    this.setState({ loading: true });
                    let url = process.env.REACT_APP_API_URL + "/api/deleteRoom?cn=" + firebase.auth().currentUser.uid;
                    fetch(url, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            roomId: this.state.roomId
                        })
                    })
                        .then((response) => {
                            if (!response.ok) {
                                this.setState({ loading: false });
                                alert("Error", response.status.toString());
                                throw Error("Error " + response.status);
                            }
                            return response.json();
                        })
                        .then((responseJson) => {
                            this.setState({ loading: false });
                            swal(
                                i18n.t(responseJson.status ? "common.archieve.success" : "technical.error", {
                                    item: i18n.t("common.unit")
                                }),
                                {
                                    icon: responseJson.status ? "success" : "error"
                                }
                            );
                            if (responseJson.status) {
                                this.props.history.push({
                                    pathname: this.state.onboarding ? "/roomList" : "/admin/room",
                                    state: {
                                        page: ONBOARDING.roomPage,
                                        onboarding: true
                                    }
                                });
                            }
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            swal(i18n.t("network.error"), {
                                icon: "error"
                            });
                            console.log(error);
                        });
                }
            });
        } else {
            swal({
                text: i18n.t("room.error.exist.contract.ongoing"),
                icon: "error"
            });
        }
    }
}
