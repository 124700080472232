import React from "react";
import Loader from "react-loader-spinner";
import i18n from "../../i18n";

export default function AppLoader(props) {
    return (
        <div>
            <div
                style={{
                    position: "fixed",
                    top: "65%",
                    left: "50%",
                    width: "30em",
                    height: "25em",
                    marginTop: "-13em",
                    marginLeft: "-15em",
                    textAlign: "center"
                }}
            >
                <Loader visible={true} type="TailSpin" color="#0099ff" height={100} width={100} />
                <br />
                {i18n.t("loading.message")}
            </div>
        </div>
    );
}
