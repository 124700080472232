import { constantType } from "../ActionType";

const INITIAL_STATE = {
    /** DASHBOARD */
    dashboardResult: null,
    isLoadingDashboardConstant: false,
    errorDashboardConstant: null
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constantType.LOAD_DASHBOARD_CONSTANT_START:
            return Object.assign({}, state, { isLoadingDashboardConstant: true });
        case constantType.LOAD_DASHBOARD_CONSTANT_SUCCESS:
            return Object.assign({}, state, {
                dashboardResult: action.payload,
                isLoadingDashboardConstant: false
            });
        case constantType.LOAD_DASHBOARD_CONSTANT_FAILURE:
            return Object.assign({}, state, {
                errorDashboardConstant: action.payload,
                isLoadingDashboardConstant: false
            });
        default:
            return state;
    }
};

export default dashboardReducer;
