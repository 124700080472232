export const translation = {
    en: {
        "register.title": "Owner Registration",
        "register.emailAddress": "Email Address",
        "register.password": "Password",
        "register.name": "Name",
        "register.phoneNumber": "Phone number",
        "register.gender": "Gender",
        "register.identityType": "Identity Type",
        "register.identityNumber": "Identity Number",
        "register.dateOfBirth": "Date of birth",
        "register.button": "Register",
        "register.validation.field": "Hello, cannot. Please ensure all information are filled up.",
        "register.validation.email.pattern": "Please enter a valid email address.",
        "register.passwordLength": "Password should be at least 6 characters",
        "register.successful": "Poof! Verification had sent ! Please check your email inbox.",
        "register.login": "Login",
        "register.validation.identityNumber.pattern": "Please enter a valid IC Number.",
        "register.validation.phoneNumber.pattern": "Please enter a valid Phone Number."
    }
};
