export const actionType = {
    LOAD_OWNER_START: "LOAD_OWNER_START",
    LOAD_OWNER_SUCCESS: "LOAD_OWNER_SUCCESS",
    LOAD_OWNER_FAILURE: "LOAD_OWNER_FAILURE",
    LOAD_AREA_START: "LOAD_AREA_START",
    LOAD_AREA_SUCCESS: "LOAD_AREA_SUCCESS",
    LOAD_AREA_FAILURE: "LOAD_AREA_FAILURE",
    LOAD_UNIT_START: "LOAD_UNIT_START",
    LOAD_UNIT_SUCCESS: "LOAD_UNIT_SUCCESS",
    LOAD_UNIT_FAILURE: "LOAD_UNIT_FAILURE",
    LOAD_ROOM_START: "LOAD_ROOM_START",
    LOAD_ROOM_SUCCESS: "LOAD_ROOM_SUCCESS",
    LOAD_ROOM_FAILURE: "LOAD_ROOM_FAILURE",
    LOAD_PARKING_START: "LOAD_PARKING_START",
    LOAD_PARKING_SUCCESS: "LOAD_PARKING_SUCCESS",
    LOAD_PARKING_FAILURE: "LOAD_PARKING_FAILURE",
    LOAD_TENANT_START: "LOAD_TENANT_START",
    LOAD_TENANT_SUCCESS: "LOAD_TENANT_SUCCESS",
    LOAD_TENANT_FAILURE: "LOAD_TENANT_FAILURE",
    LOAD_REQUEST_START: "LOAD_REQUEST_START",
    LOAD_REQUEST_SUCCESS: "LOAD_REQUEST_SUCCESS",
    LOAD_REQUEST_FAILURE: "LOAD_REQUEST_FAILURE",
    LOAD_BILLING_COUNT_START: "LOAD_BILLING_COUNT_START",
    LOAD_BILLING_COUNT_SUCCESS: "LOAD_BILLING_COUNT_SUCCESS",
    LOAD_BILLING_COUNT_FAILURE: "LOAD_BILLING_COUNT_FAILURE",
    CREATE_TENANT_START: "CREATE_TENANT_START",
    CREATE_TENANT_SUCCESS: "CREATE_TENANT_SUCCESS",
    CREATE_TENANT_FAILURE: "CREATE_TENANT_FAILURE",
    UPDATE_FIRSTTIMELOGIN_START: "UPDATE_FIRSTTIMELOGIN_START",
    UPDATE_FIRSTTIMELOGIN_SUCCESS: "UPDATE_FIRSTTIMELOGIN_SUCCESS",
    UPDATE_FIRSTTIMELOGIN_FAILURE: "UPDATE_FIRSTTIMELOGIN_FAILURE"
};

export const constantType = {
    LOAD_DASHBOARD_CONSTANT_START: "LOAD_DASHBOARD_CONSTANT_START",
    LOAD_DASHBOARD_CONSTANT_SUCCESS: "LOAD_DASHBOARD_CONSTANT_SUCCESS",
    LOAD_DASHBOARD_CONSTANT_FAILURE: "LOAD_DASHBOARD_CONSTANT_FAILURE",
    LOAD_AREA_CONSTANT_START: "LOAD_AREA_CONSTANT_START",
    LOAD_AREA_CONSTANT_SUCCESS: "LOAD_AREA_CONSTANT_SUCCESS",
    LOAD_AREA_CONSTANT_FAILURE: "LOAD_AREA_CONSTANT_FAILURE",
    LOAD_UNIT_CONSTANT_START: "LOAD_UNIT_CONSTANT_START",
    LOAD_UNIT_CONSTANT_SUCCESS: "LOAD_UNIT_CONSTANT_SUCCESS",
    LOAD_UNIT_CONSTANT_FAILURE: "LOAD_UNIT_CONSTANT_FAILURE",
    LOAD_ROOM_CONSTANT_START: "LOAD_ROOM_CONSTANT_START",
    LOAD_ROOM_CONSTANT_SUCCESS: "LOAD_ROOM_CONSTANT_SUCCESS",
    LOAD_ROOM_CONSTANT_FAILURE: "LOAD_ROOM_CONSTANT_FAILURE",
    LOAD_PARKING_CONSTANT_START: "LOAD_PARKING_CONSTANT_START",
    LOAD_PARKING_CONSTANT_SUCCESS: "LOAD_PARKING_CONSTANT_SUCCESS",
    LOAD_PARKING_CONSTANT_FAILURE: "LOAD_PARKING_CONSTANT_FAILURE",
    LOAD_UTILITY_CONSTANT_START: "LOAD_UTILITY_CONSTANT_START",
    LOAD_UTILITY_CONSTANT_SUCCESS: "LOAD_UTILITY_CONSTANT_SUCCESS",
    LOAD_UTILITY_CONSTANT_FAILURE: "LOAD_UTILITY_CONSTANT_FAILURE",
    LOAD_UTILITY_CONSTANT_END: "LOAD_UTILITY_CONSTANT_END",
    LOAD_AMENITY_CONSTANT_START: "LOAD_AMENITY_CONSTANT_START",
    LOAD_AMENITY_CONSTANT_SUCCESS: "LOAD_AMENITY_CONSTANT_SUCCESS",
    LOAD_AMENITY_CONSTANT_FAILURE: "LOAD_AMENITY_CONSTANT_FAILURE",
    LOAD_AMENITY_CONSTANT_END: "LOAD_AMENITY_CONSTANT_END"
};
