import React from "react";
import AreaModel from "./AreaModel";
import firebase from "../firebase";
import swal from "@sweetalert/with-react";
import i18n from "../../i18n";
import { NUM_OF_RECORDS_PER_PAGE, FIRST_PAGE, ONBOARDING } from "../Properties";

export default class AreaManagerImpl extends AreaModel {
    constructor(props) {
        super(props);
        this._init = this._init.bind(this);
        this._create = this._create.bind(this);
        this._view = this._view.bind(this);
        this.searchPostcode = this.searchPostcode.bind(this);
        this.searchCity = this.searchCity.bind(this);
        this.searchState = this.searchState.bind(this);
        this.onEvent = this.onEvent.bind(this);
        this.isDuplicated = this.isDuplicated.bind(this);
        this._createAction = this._createAction.bind(this);
        this._updateAction = this._updateAction.bind(this);
        this.getAreaOptions = this.getAreaOptions.bind(this);
        this.checkingState = this.checkingState.bind(this);
        this._archieve = this._archieve.bind(this);
    }

    _init(areas) {
        var output = [];
        if (areas.length > 0) {
            areas.forEach((area) => {
                output.push(Object.values(area).splice(2));
            });
        }
        this.backups = areas;
        let dataCount = output.length;
        let displayResult = [];
        /** size for number of records per page */
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        this.setState({ displayResult, dataCount, areas, loading: false });
    }

    searchPostcode(e) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.postcode.toString().indexOf(e.target.value) > -1)
            .forEach((result) => output.push(Object.values(result).splice(2)));
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    searchCity(e) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.city.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1)
            .forEach((result) => output.push(Object.values(result).splice(2)));
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    searchState(e) {
        let output = [];
        let displayResult = [];
        this.backups
            .filter((x) => x.state.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1)
            .forEach((result) => output.push(Object.values(result).splice(2)));
        let dataCount = output.length;
        while (output.length > 0) {
            let temp = output.splice(NUM_OF_RECORDS_PER_PAGE);
            displayResult.push(output);
            output = temp;
        }
        return this.setState({ displayResult, dataCount, activePage: FIRST_PAGE });
    }

    _create() {
        this.props.history.push({
            pathname: this.state.onboarding ? "/areaForm" : "/admin/areaForm",
            state: { areas: this.state.areas, onboarding: this.state.onboarding }
        });
    }

    _view(index) {
        this.state.areas.forEach((area) => {
            area.index === index &&
                this.props.history.push({
                    pathname: this.state.onboarding ? "/areaForm" : "/admin/areaForm",
                    state: { area, edit: true, areas: this.state.areas, onboarding: this.state.onboarding }
                });
        });
    }

    getAreaOptions() {
        if (this.state.postcode !== "") {
            this.setState({ searchLoading: true, inputDisabled: true });
            let url = process.env.REACT_APP_API_URL + "/api/getAreaOption?cn=" + firebase.auth().currentUser.uid;

            fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    word: this.state.postcode
                })
            })
                .then((response) => {
                    if (!response.ok) {
                        this.setState({ searchLoading: false });
                        alert("Error", response.status.toString());
                        throw Error("Error " + response.status);
                    }
                    return response.json();
                })
                .then((responseJson) => {
                    this.stateList = [];
                    this.cityList = [];
                    if (responseJson.areaOptions.length > 0) {
                        var areaOptions = [];
                        var cityOptions = [];
                        var stateOptions = [];
                        responseJson.areaOptions.forEach((x) => {
                            areaOptions.push(x[1]);
                            this.checkingCity(x[2]) && cityOptions.push(x[2]);
                            this.checkingState(x[3]) && stateOptions.push(x[3]);
                        });
                        this.setState({
                            areaOptions,
                            cityOptions,
                            stateOptions,
                            inputDisabled: false
                        });
                    } else {
                        swal(i18n.t("common.search.fail"), {
                            icon: "warning"
                        });
                    }
                    this.setState({ searchLoading: false, loading: false });
                })
                .catch((error) => {
                    this.setState({ searchLoading: false });
                    console.log(error);
                    setTimeout(() => {
                        swal(i18n.t("network.error"), {
                            icon: "error"
                        });
                    }, 100);
                });
        } else {
            swal(i18n.t("area.input.validation.postcode"), {
                icon: "warning"
            });
        }
    }

    async onEvent(e) {
        e.preventDefault();
        this.setState({
            locationNameErrorMessage: null,
            addressErrorMessage: null,
            postcodeErrorMessage: null,
            areaErrorMessage: null,
            cityErrorMessage: null,
            stateErrorMessage: null
        });
        if (
            this.state.locationName &&
            this.state.areaName &&
            this.state.address &&
            this.state.postcode &&
            this.state.city &&
            this.state.state
        ) {
            if (!this.isDuplicated()) {
                this.state.edit ? this._updateAction() : this._createAction();
            } else {
                swal(i18n.t("area.input.duplicate.locationName"), {
                    icon: "error"
                });
            }
        } else {
            this.setState({
                locationNameErrorMessage: !this.state.locationName && i18n.t("area.input.validation.locationName"),
                addressErrorMessage: !this.state.address && i18n.t("area.input.validation.address"),
                postcodeErrorMessage: !this.state.postcode && i18n.t("area.input.validation.postcode"),
                areaErrorMessage: !this.state.areaName && i18n.t("area.input.validation.area"),
                cityErrorMessage: !this.state.city && i18n.t("area.input.validation.city"),
                stateErrorMessage: !this.state.state && i18n.t("area.input.validation.state")
            });
        }
    }

    isDuplicated() {
        let isDuplicate = false;
        for (let i = 0; i < this.state.areas.length; i++) {
            if (
                this.state.areas[i].areaId !== this.state.areaId &&
                this.state.areas[i].locationName.toUpperCase() === this.state.locationName.toUpperCase() &&
                this.state.areas[i].areaName.toUpperCase() === this.state.areaName.toUpperCase() &&
                this.state.areas[i].address.toUpperCase() === this.state.address.toUpperCase() &&
                this.state.areas[i].postcode === this.state.postcode &&
                this.state.areas[i].city === this.state.city &&
                this.state.areas[i].state === this.state.state
            ) {
                isDuplicate = true;
                break;
            }
        }
        return isDuplicate;
    }

    checkingCity(value) {
        if (this.cityList.includes(value)) {
            return false;
        } else {
            this.cityList.push(value);
            return true;
        }
    }

    checkingState(value) {
        if (this.stateList.includes(value)) {
            return false;
        } else {
            this.stateList.push(value);
            return true;
        }
    }

    _createAction() {
        swal({
            title: i18n.t("dialog.check.detail"),
            content: (
                <table>
                    <tbody>
                        <tr>
                            <td className="modelTitle">{i18n.t("area.locationName")} :</td>
                            <td className="modelDesc">{this.state.locationName}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("area.address")} :</td>
                            <td className="modelDesc">{this.state.address}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("area.postcode")} :</td>
                            <td className="modelDesc">{this.state.postcode}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("area.areaName")} :</td>
                            <td className="modelDesc">{this.state.areaName}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("area.city")} :</td>
                            <td className="modelDesc">{this.state.city}</td>
                        </tr>
                        <tr>
                            <td className="modelTitle">{i18n.t("area.state")} :</td>
                            <td className="modelDesc">{this.state.state}</td>
                        </tr>
                    </tbody>
                </table>
            ),
            icon: "warning",
            buttons: ["Cancel", "Create"]
        }).then((Confirm) => {
            if (Confirm) {
                this.scrollToTop();
                this.setState({ loading: true });
                let url = process.env.REACT_APP_API_URL + "/api/createArea?cn=" + firebase.auth().currentUser.uid;
                fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        locationName: this.state.locationName,
                        areaName: this.state.areaName,
                        address: this.state.address,
                        postcode: this.state.postcode,
                        city: this.state.city,
                        state: this.state.state
                    })
                })
                    .then((response) => {
                        if (!response.ok) {
                            this.setState({ loading: false });
                            alert("Error", response.status.toString());
                            throw Error("Error " + response.status);
                        }
                        return response.json();
                    })
                    .then((responseJson) => {
                        this.setState({ loading: false });
                        swal(i18n.t(responseJson.status ? "common.create.success" : "technical.error"), {
                            icon: responseJson.status ? "success" : "error"
                        });
                        if (responseJson.status) {
                            this.props.history.push({
                                pathname: this.state.onboarding ? "/onboarding" : "/admin/area",
                                state: {
                                    page: ONBOARDING.areaPage
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        this.setState({ loading: false });
                        swal(i18n.t("network.error"), {
                            icon: "error"
                        });
                        console.log(error);
                    });
            }
        });
    }

    _updateAction() {
        this.scrollToTop();
        this.setState({ loading: true });
        let url = process.env.REACT_APP_API_URL + "/api/updateArea?cn=" + firebase.auth().currentUser.uid;
        fetch(url, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                areaId: this.state.areaId,
                locationName: this.state.locationName,
                areaName: this.state.areaName,
                address: this.state.address,
                postcode: this.state.postcode,
                city: this.state.city,
                state: this.state.state
            })
        })
            .then((response) => {
                if (!response.ok) {
                    this.setState({ loading: false });
                    alert("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                this.setState({ loading: false });
                swal(i18n.t(responseJson.status ? "common.update.success" : "technical.error"), {
                    icon: responseJson.status ? "success" : "error"
                });
                if (responseJson.status) {
                    this.props.history.push({
                        pathname: this.state.onboarding ? "/onboarding" : "/admin/area",
                        state: {
                            page: ONBOARDING.areaPage
                        }
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                swal(i18n.t("network.error"), {
                    icon: "error"
                });
                console.log(error);
            });
    }

    _archieve(e) {
        e.preventDefault();
        this.setState({ loading2: true });
        this.props.loadUnits(this.state.areaId);
        this.props.loadParkings(this.state.areaId);

        this.intervalID = setInterval(() => {
            const { isUnitCacheLoading, unitList, isParkingCacheLoading, parkingList } = this.props;
            if (!isUnitCacheLoading && !isParkingCacheLoading) {
                this.setState({ loading2: false });
                const numOfUnitRecord = unitList.length;
                const numOfParkingRecord = parkingList.length;
                if (numOfUnitRecord === 0 && numOfParkingRecord === 0) {
                    swal(i18n.t("common.archieve.confirmation"), {
                        icon: "warning",
                        buttons: [i18n.t("common.no"), i18n.t("common.yes")]
                    })
                        .then((confirm) => {
                            if (confirm) {
                                this.scrollToTop();
                                this.setState({ loading: true });
                                let url =
                                    process.env.REACT_APP_API_URL +
                                    "/api/deleteArea?cn=" +
                                    firebase.auth().currentUser.uid;

                                fetch(url, {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        areaId: this.state.areaId
                                    })
                                })
                                    .then((response) => {
                                        if (!response.ok) {
                                            this.setState({ loading: false });
                                            alert("Error", response.status.toString());
                                            throw Error("Error " + response.status);
                                        }
                                        return response.json();
                                    })
                                    .then((responseJson) => {
                                        this.setState({ loading: false });
                                        swal(
                                            i18n.t(
                                                responseJson.status ? "common.archieve.success" : "technical.error",
                                                {
                                                    item: i18n.t("common.location")
                                                }
                                            ),
                                            {
                                                icon: responseJson.status ? "success" : "error"
                                            }
                                        );
                                        if (responseJson.status) {
                                            this.props.history.push({
                                                pathname: this.state.onboarding ? "/onboarding" : "/admin/area",
                                                state: {
                                                    page: ONBOARDING.areaPage
                                                }
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        this.setState({ loading: false });
                                        swal(i18n.t("network.error"), {
                                            icon: "error"
                                        });
                                        console.log(error);
                                    });
                            }
                        })
                        .catch((error) => {
                            this.setState({ loading: false });
                            swal(i18n.t("network.error"), {
                                icon: "error"
                            });
                            console.log(error);
                        });
                } else {
                    swal({
                        text: i18n.t(
                            numOfUnitRecord !== 0 && numOfParkingRecord !== 0
                                ? "area.error.exist.unit.parking.record"
                                : numOfUnitRecord !== 0
                                ? "area.error.exist.unit.record"
                                : "area.error.exist.parking.record",
                            {
                                number: numOfUnitRecord !== 0 ? numOfUnitRecord : numOfParkingRecord,
                                number2: numOfParkingRecord
                            }
                        ),
                        icon: "error"
                    });
                }
                clearInterval(this.intervalID);
            }
        }, 100);
    }
}
