import firebase from "views/firebase";
import { actionType, constantType } from "../ActionType";

export const loadRoomConstant = () => {
    return (dispatch) => {
        dispatch({ type: constantType.LOAD_ROOM_CONSTANT_START });
        let url = process.env.REACT_APP_API_URL + "/api/getRoom?cn=" + firebase.auth().currentUser.uid;
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                let rooms = [],
                    roomName = {},
                    roomPrice = {};
                if (responseJson.status) {
                    responseJson.roomList.forEach((doc) => {
                        rooms.push({
                            id: doc.roomId,
                            areaId: doc.areaId,
                            unitId: doc.unitId,
                            roomName: doc.roomName,
                            availability: doc.availability
                        });
                        roomName[doc.roomId] = doc.roomName;
                        roomPrice[doc.roomId] = doc.rentalUnitPrice;
                    });
                }
                dispatch({ type: constantType.LOAD_ROOM_CONSTANT_SUCCESS, rooms, roomName, roomPrice });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: constantType.LOAD_ROOM_CONSTANT_FAILURE, payload: error });
            });
    };
};

export const loadRooms = () => {
    return (dispatch) => {
        dispatch({ type: actionType.LOAD_ROOM_START });
        let url = process.env.REACT_APP_API_URL + "/api/getRoom?cn=" + firebase.auth().currentUser.uid;
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (!response.ok) {
                    console.log("Error", response.status.toString());
                    throw Error("Error " + response.status);
                }
                return response.json();
            })
            .then((responseJson) => {
                dispatch({
                    type: actionType.LOAD_ROOM_SUCCESS,
                    payload: responseJson.status ? responseJson.roomList : []
                });
            })
            .catch((error) => {
                console.error(error);
                dispatch({ type: actionType.LOAD_ROOM_FAILURE, payload: error });
            });
    };
};
