export const translation = {
    en: {
        "dashboard.title": "Dashboard",
        "dashboard.title.payment": "Outstanding Payments ",
        "dashboard.title.room": "Empty Rooms",
        "dashboard.title.parking": "Available Parkings",
        "dashboard.title.complaint": "Active Complaints",
        "dashboard.view": "View {{content}}"
    }
};
