import React from "react";
import { Card, CardHeader, CardContent, CardMedia, Typography, withStyles } from "@material-ui/core";
import Loader from "react-loader-spinner";
import swal from "@sweetalert/with-react";
import firebase from "../../views/firebase";
import { paymentTypes, paymentStatus, contractStatus } from "../Properties";
import { firestore } from "firebase";
import CommonManagerImpl from "views/CommonManagerImpl";

const styles = (theme) => ({
    card: {
        margin: "auto",
        alignSelf: "center",
        maxWidth: "96%",
        minWidth: "40%"
    },
    media: {
        height: "150px",
        width: "150px",
        margin: "auto auto"
    },
    header: {
        textAlign: "center"
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }
});

class FPXInstantPayRedirect extends CommonManagerImpl {
    state = {
        ownerID: null,
        areaID: null,
        unitID: null,
        roomID: null,
        contractID: null,
        billingID: null,
        fpx_fpxTxnId: null,
        fpx_sellerExOrderNo: null,
        fpx_fpxTxnTime: null,
        fpx_sellerOrderNo: null,
        fpx_txnCurrency: null,
        fpx_txnAmount: null,
        fpx_buyerBankId: null,
        fpx_type: null,
        fpx_collectionAmt: null,
        bankList: [],
        fpxStatus: [],
        spinner: false
    };

    componentDidMount() {
        this.setState({ spinner: true });
        Promise.all([
            fetch("https://demo.curlec.com/curlec-services/banks", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: "method=01&msgToken=01"
            }).then((res) => res.json()),
            firebase.firestore().collection("fpxStatusCodes/").get()
        ]).then(([bankListResponse, querySnapshot]) => {
            const fpxStatusCodes = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

            const params = new URLSearchParams(window.location.search);
            const fpx_fpxTxnId = params.get("fpx_fpxTxnId");
            const fpx_sellerExOrderNo = params.get("fpx_sellerExOrderNo");
            const fpx_fpxTxnTime = params.get("fpx_fpxTxnTime");
            const fpx_sellerOrderNo = params.get("fpx_sellerOrderNo"); // ref no
            const fpx_txnCurrency = params.get("fpx_txnCurrency");
            const fpx_txnAmount = params.get("fpx_txnAmount");
            const fpx_buyerBankId = params.get("fpx_buyerBankId");
            const fpx_debitAuthCode = params.get("fpx_debitAuthCode");

            const paymentType = fpx_sellerOrderNo?.slice(0, 2);
            const fpxStatus =
                fpxStatusCodes.find((responseCode) => responseCode.code === fpx_debitAuthCode)?.description ??
                "Error...";
            if (
                fpx_fpxTxnId &&
                fpx_sellerExOrderNo &&
                fpx_fpxTxnTime &&
                fpx_sellerOrderNo &&
                fpx_txnCurrency &&
                fpx_txnAmount &&
                fpx_buyerBankId &&
                fpx_debitAuthCode &&
                ["Billing", "Contract Fee", "Direct Debit"].includes(paymentTypes[paymentType])
            ) {
                return Promise.all([
                    ["Billing", "Contract Fee"].includes(paymentTypes[paymentType])
                        ? firebase
                              .firestore()
                              .collectionGroup("payments")
                              .where("sellerOrderNo", "==", fpx_sellerOrderNo)
                              .get()
                        : Promise.resolve(),
                    paymentTypes[paymentType] === "Direct Debit"
                        ? firebase
                              .firestore()
                              .collectionGroup("directDebitMandatePayments")
                              .where("sellerOrderNo", "==", fpx_sellerOrderNo)
                              .get()
                        : Promise.resolve()
                ]).then(([paymentsQuerySnapshot, directDebitMandatePaymentsQuerySnapshot]) => {
                    const targetPayment = (paymentsQuerySnapshot ?? directDebitMandatePaymentsQuerySnapshot).docs.map(
                        (doc) => ({
                            id: doc.id,
                            ...doc.data()
                        })
                    )[0];

                    if (targetPayment) {
                        const { ownerID, areaID, unitID, roomID, contractID, billingID, id: paymentID } = targetPayment;
                        const contractDocumentRef = firebase
                            .firestore()
                            .collection("owners")
                            .doc(ownerID)
                            .collection("areas")
                            .doc(areaID)
                            .collection("units")
                            .doc(unitID)
                            .collection("rooms")
                            .doc(roomID)
                            .collection("contracts")
                            .doc(contractID);

                        if (paymentType === "BL") {
                            return contractDocumentRef
                                .collection("billings")
                                .doc(billingID)
                                .collection("payments")
                                .doc(paymentID)
                                .update({
                                    status:
                                        fpxStatus === "Approved"
                                            ? this.getKeyByValue(paymentStatus, "Approved")
                                            : this.getKeyByValue(paymentStatus, "Rejected"),
                                    approvedDate:
                                        fpxStatus === "Approved" ? firestore.FieldValue.serverTimestamp() : null,
                                    approvedBy: fpxStatus === "Approved" ? "system" : null,
                                    fpxStatusCode: fpx_debitAuthCode,
                                    updatedAt: firestore.FieldValue.serverTimestamp()
                                })
                                .then((_) =>
                                    this.setState({
                                        fpx_fpxTxnId,
                                        fpx_sellerExOrderNo,
                                        fpx_fpxTxnTime,
                                        fpx_sellerOrderNo,
                                        fpx_txnCurrency,
                                        fpx_txnAmount,
                                        fpx_buyerBankId,
                                        bankList: bankListResponse.Response[0],
                                        fpxStatus,
                                        spinner: false
                                    })
                                );
                        } else if (paymentType === "CF") {
                            return contractDocumentRef
                                .collection("payments")
                                .doc(paymentID)
                                .update({
                                    status:
                                        fpxStatus === "Approved"
                                            ? this.getKeyByValue(paymentStatus, "Approved")
                                            : this.getKeyByValue(paymentStatus, "Rejected"),
                                    approvedDate:
                                        fpxStatus === "Approved" ? firestore.FieldValue.serverTimestamp() : null,
                                    approvedBy: fpxStatus === "Approved" ? "system" : null,
                                    fpxStatusCode: fpx_debitAuthCode,
                                    updatedAt: firestore.FieldValue.serverTimestamp()
                                })
                                .then(async (_) => {
                                    return Promise.all([
                                        contractDocumentRef.collection("contractFees").get(),
                                        contractDocumentRef.collection("payments").get()
                                    ]).then(([contractFeesQuerySnapshot, $paymentsQuerySnapshot]) => {
                                        const contractFeesTotalAmount = contractFeesQuerySnapshot.docs
                                            .map((doc) => ({
                                                id: doc.id,
                                                ...doc.data()
                                            }))
                                            .reduce((result, contractFee) => result + contractFee.fee, 0);

                                        const contractFeesPayments = $paymentsQuerySnapshot.docs.map((doc) => ({
                                            id: doc.id,
                                            ...doc.data()
                                        }));

                                        return Promise.all(
                                            contractFeesPayments.map((payment) =>
                                                contractDocumentRef
                                                    .collection("payments")
                                                    .doc(payment.id)
                                                    .collection("paymentDetails")
                                                    .get()
                                            )
                                        ).then((paymentDetailsQeurySnapshots) => {
                                            const contractFeesPaymentsWithDetails = paymentDetailsQeurySnapshots.map(
                                                (paymentDetailsQuerySnapshot, index) => ({
                                                    ...contractFeesPayments[index],
                                                    paymentDetails: paymentDetailsQuerySnapshot.docs.map((doc) => ({
                                                        id: doc.id,
                                                        ...doc.data()
                                                    }))
                                                })
                                            );

                                            const contractFeesPaymentsTotalAmount = contractFeesPaymentsWithDetails.reduce(
                                                (result, contractFeesPayment) =>
                                                    result +
                                                    contractFeesPayment.paymentDetails.reduce(
                                                        ($result, paymentDetail) => $result + paymentDetail.amount,
                                                        0
                                                    ),
                                                0
                                            );

                                            const contractFeesCleared =
                                                contractFeesTotalAmount === contractFeesPaymentsTotalAmount;

                                            return contractDocumentRef
                                                .update({
                                                    status: contractFeesCleared
                                                        ? this.getKeyByValue(contractStatus, "Completed")
                                                        : this.getKeyByValue(contractStatus, "Pending"),
                                                    updatedAt: firestore.FieldValue.serverTimestamp()
                                                })
                                                .then((_) =>
                                                    this.setState({
                                                        fpx_fpxTxnId,
                                                        fpx_sellerExOrderNo,
                                                        fpx_fpxTxnTime,
                                                        fpx_sellerOrderNo,
                                                        fpx_txnCurrency,
                                                        fpx_txnAmount,
                                                        fpx_buyerBankId,
                                                        bankList: bankListResponse.Response[0],
                                                        fpxStatus,
                                                        spinner: false
                                                    })
                                                );
                                        });
                                    });
                                });
                        } else if (paymentTypes[paymentType] === "Direct Debit") {
                            return fetch("https://demo.curlec.com/curlec-services", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/x-www-form-urlencoded"
                                },
                                body: `order_no=${fpx_sellerOrderNo}&merchantId=2155522&method=07&action=get`
                            })
                                .then((res) => res.json())
                                .then((mandateResponse) => {
                                    const targetMandate = mandateResponse.Response[0];
                                    const { fpx_type, fpx_collectionAmt, fpx_txnAmount } = targetMandate;

                                    return Promise.all([
                                        contractDocumentRef
                                            .collection("directDebitMandatePayments")
                                            .doc(paymentID)
                                            .update({
                                                status:
                                                    fpxStatus === "Approved"
                                                        ? this.getKeyByValue(paymentStatus, "Approved")
                                                        : this.getKeyByValue(paymentStatus, "Rejected"),
                                                approvedDate:
                                                    fpxStatus === "Approved"
                                                        ? firestore.FieldValue.serverTimestamp()
                                                        : null,
                                                approvedBy: fpxStatus === "Approved" ? "system" : null,
                                                fpxStatusCode: fpx_debitAuthCode,
                                                updatedAt: firestore.FieldValue.serverTimestamp()
                                            }),
                                        contractDocumentRef.update({
                                            directDebit: {
                                                mandateReferenceNo: fpx_sellerOrderNo,
                                                status: "Processing"
                                            },
                                            directDebitEnabled: false
                                        })
                                    ]).then((_) =>
                                        this.setState({
                                            fpx_fpxTxnId,
                                            fpx_sellerExOrderNo,
                                            fpx_fpxTxnTime,
                                            fpx_sellerOrderNo,
                                            fpx_txnCurrency,
                                            fpx_txnAmount,
                                            fpx_buyerBankId,
                                            bankList: bankListResponse.Response[0],
                                            fpxStatus,
                                            fpx_type,
                                            fpx_collectionAmt,
                                            spinner: false
                                        })
                                    );
                                });
                        }
                    } else {
                        this.setState({
                            spinner: false
                        });
                        return swal("Something went wrong!", {
                            icon: "error"
                        });
                    }
                });
            } else {
                this.setState({
                    spinner: false
                });
                return swal("Something went wrong!", {
                    icon: "error"
                });
            }
        });
    }

    render() {
        const { classes } = this.props;
        const {
            fpx_fpxTxnId,
            fpx_sellerExOrderNo,
            fpx_fpxTxnTime,
            fpx_sellerOrderNo,
            fpx_txnCurrency,
            fpx_txnAmount,
            fpx_buyerBankId,
            bankList,
            fpxStatus,
            fpx_type,
            fpx_collectionAmt,
            spinner
        } = this.state;
        const paymentType = fpx_sellerOrderNo?.slice(0, 2);

        if (spinner) {
            return (
                <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>
                    <Loader
                        visible={this.state.loading}
                        type="TailSpin" //Puff
                        color="#0099ff"
                        height={100}
                        width={100}
                    />
                </div>
            );
        }

        return (
            <div style={{ display: "flex", height: "100%" }}>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.header}
                        title={
                            "Transaction Summary" +
                            (paymentTypes[paymentType] === "Direct Debit" ? " (Direct Debit Mandate)" : "")
                        }
                    />
                    <CardMedia
                        className={classes.media}
                        image={require("../../assets/img/transaction-summary.png")}
                        title="Transaction Summary"
                    />
                    <CardContent className={classes.cardContent}>
                        <DataRow title={"Transaction Status:"} subtitle={fpxStatus} />
                        <DataRow title={"Transaction ID:"} subtitle={fpx_fpxTxnId ?? "Error..."} />
                        <DataRow title={"Exchange Order Number:"} subtitle={fpx_sellerExOrderNo ?? "Error..."} />
                        <DataRow title={"Transaction Time:"} subtitle={fpx_fpxTxnTime ?? "Error..."} />
                        <DataRow title={"Reference No.:"} subtitle={fpx_sellerOrderNo ?? "Error..."} />
                        {fpx_type && <DataRow title={"Transaction Type"} subtitle={fpx_type} />}
                        <DataRow title={"Currency:"} subtitle={fpx_txnCurrency ?? "Error..."} />
                        <DataRow
                            title={
                                paymentTypes[paymentType] === "Direct Debit"
                                    ? "Max Amount per DD Transaction"
                                    : "Transaction Amount:"
                            }
                            subtitle={fpx_txnAmount ?? "Error..."}
                        />
                        {fpx_collectionAmt && <DataRow title={"Collection Amount"} subtitle={fpx_collectionAmt} />}
                        <DataRow
                            title={"Bank Name:"}
                            subtitle={
                                bankList.find((bank) => bank.code.includes(fpx_buyerBankId))?.name[0] ?? "Error..."
                            }
                        />
                    </CardContent>
                </Card>
            </div>
        );
    }
}

const DataRow = (props) => (
    <div
        style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}
    >
        <Typography style={{ marginRight: "0.5rem" }} variant="subtitle1" color="textPrimary">
            {props.title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
            {props.subtitle}
        </Typography>
    </div>
);

export default withStyles(styles)(FPXInstantPayRedirect);
