import CommonManagerImpl from "views/CommonManagerImpl";
import { FIRST_PAGE } from "../Properties";

export default class UnitModel extends CommonManagerImpl {
    constructor(props) {
        super(props);
        this.state = {
            units: this.props.location.state?.units?? null,
            displayResult: null,
            loading: true,
            loading2: false,
            edit: this.props.location.state?.edit ?? false,
            unitId: this.props.location.state?.unit?.unitId ?? "",
            unitNumber: this.props.location.state?.unit?.unitNumber ?? "",
            locationName: this.props.location.state?.unit?.locationName ?? "",
            areaId: this.props.location.state?.unit?.areaId ?? "",
            unitNumberErrorMessage: null,
            areaErrorMessage: null,
            activePage: this.props.location.state?.activePage ?? FIRST_PAGE,
            dataCount: 0,
            onboarding: this.props.location.state?.onboarding ?? false
        };
    }

    backups = [];
}
