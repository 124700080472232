import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import qrcode from "./Component/qrcode.png";
import googleplay from "./Component/googleplay.png";
import applestore from "./Component/applestore.svg";
import CommonManagerImpl from "views/CommonManagerImpl.js";
import i18n from "../../i18n";
import Background from "../../assets/img/background.jpg";

import "./RegisterTenant.css";

export default class RegisterTenant extends CommonManagerImpl {
    render() {
        return (
            <Grid container component="main" style={{ height: this.state.height * 0.9 }}>
                <div className="darkoverlay">
                    <div className="background">
                        <img src={Background} alt="Logo" />
                    </div>
                </div>
                <Card className="form">
                    <CardHeader color="yellow">
                        <h4
                            style={{
                                fontSize: "28px",
                                fontWeight: "bold",
                                fontFamily: "Franklin Gothic",
                                textAlign: "center"
                            }}
                        >
                            {i18n.t("tenant.register.title")}
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <img
                            style={{
                                display: "block",
                                margin: "0 auto"
                            }}
                            height="350px"
                            width="350px"
                            src={qrcode}
                            alt={"qrcode"}
                        />
                        <Grid container>
                            <Grid item xs={6}>
                                <img
                                    style={{ width: "99%", marginTop: "-15px", marginLeft: "15px" }}
                                    width="auto"
                                    src={googleplay}
                                    alt={"googleplay"}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <img
                                    style={{ width: "80%", marginLeft: "15px" }}
                                    width="auto"
                                    src={applestore}
                                    alt={"applestore"}
                                />
                            </Grid>
                        </Grid>
                        <Box mt={4}>
                            <div variant="body2" color="textSecondary" align="center">
                                {i18n.t("common.copyright", {
                                    brandName: i18n.t("common.brandName"),
                                    year: new Date().getFullYear()
                                })}
                            </div>
                        </Box>
                    </CardBody>
                </Card>
            </Grid>
        );
    }
}
