import { actionType } from "../ActionType";

const INITIAL_STATE = {
    requestFormList: [],
    isLoading: false,
    error: null
};

const requestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionType.LOAD_REQUEST_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.LOAD_REQUEST_SUCCESS:
            return Object.assign({}, state, { requestFormList: action.payload, isLoading: false });
        case actionType.LOAD_REQUEST_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        default:
            return state;
    }
};

export default requestReducer;
