import React from "react";
// @material-ui/core components
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import Loader from "react-loader-spinner";
import RoomManagerImpl from "./RoomManagerImpl";
import { Icon } from "@material-ui/core";
import { connect } from "react-redux";
import * as areaActions from "../../redux/actions/AreaAction";
import * as unitActions from "../../redux/actions/UnitAction";
import i18n from "../../i18n";
import { FIRST_PAGE, ONBOARDING } from "../Properties";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import { DropzoneArea } from "material-ui-dropzone";
import Header from "../Onboarding/Header.js";
import Footer from "../Onboarding/Footer.js";
import Background from "assets/img/onboardingBackground.jpg";

class RoomForm extends RoomManagerImpl {
    componentDidMount() {
        this.setState({ loading: true });
        this.props.loadAreaConstant();
        this.props.loadUnitConstant();
        this.intervalID = setInterval(() => {
            const { isAreaConstantCacheLoading, isUnitConstantCacheLoading } = this.props;
            if (!isAreaConstantCacheLoading && !isUnitConstantCacheLoading) {
                if (this.state.edit) {
                    this._loadImage();
                } else {
                    this.setState({ loading: false });
                }
                clearInterval(this.intervalID);
            }
        }, 100);
    }

    render() {
        const { areas } = this.props;
        return (
            <div className={this.state.onboarding ? "darkoverlay" : ""}>
                {this.state.onboarding && (
                    <div className="background">
                        <img src={Background} alt="Logo" />
                    </div>
                )}
                 {this.state.onboarding && <Header />}
                 <div
                    className="form"
                    style={{
                        maxWidth: this.state.onboarding ? "1200px" : "none",
                        margin: this.state.onboarding ? "50px auto" : "none"
                    }}
                >
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <Loader
                        visible={this.state.loading}
                        type="TailSpin" //Puff
                        color="#0099ff"
                        height={100}
                        width={100}
                    />
                </div>
                {!this.state.loading && (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card profile>
                                <CardAvatar style={{ display: "flex", justifyContent: "center" }}>
                                    <Card
                                        style={{
                                            margin: "-60px 30px 0px",
                                            maxWidth: 480,
                                            maxHeight: 240,
                                            minWidth: 480,
                                            minHeight: 240,
                                            borderRadius: "6px",
                                            boxShadow:
                                                "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
                                        }}
                                    >
                                        {this.state.roomPicture !== null ? (
                                            <img
                                                style={{
                                                    maxWidth: 480,
                                                    maxHeight: 240,
                                                    minWidth: 480,
                                                    minHeight: 240,
                                                    cursor: "pointer"
                                                }}
                                                alt={"roomImage"}
                                                src={this.state.roomPicture}
                                                onClick={(e) => document.getElementById("inputFile").click()}
                                            />
                                        ) : (
                                            <DropzoneArea
                                                showPreviewsInDropzone={false}
                                                acceptedFiles={["image/jpeg, image/png"]}
                                                filesLimit={1}
                                                dropzoneText={"Drag and drop an image here"}
                                                onChange={(files) => {
                                                    this.fileChangedHandler(files);
                                                }}
                                            />
                                        )}
                                    </Card>
                                    <input
                                        id="inputFile"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            if (event.target.files.length > 0) {
                                                this.setState({ imageErrorMessage: null });
                                            }
                                            this.fileChangedHandler(event.target.files);
                                        }}
                                    />
                                </CardAvatar>
                                <CardBody>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Button
                                            color="info"
                                            onClick={(e) => document.getElementById("inputFile").click()}
                                        >
                                            <strong>{i18n.t("room.uploadImage").toUpperCase()}</strong>
                                        </Button>
                                        {this.state.imageErrorMessage && (
                                            <span style={{ color: "red" }}>{this.state.imageErrorMessage}</span>
                                        )}
                                    </div>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText={i18n.t("room.roomNameOpt")}
                                                id="roomName"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={this.state.roomName}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        this.setState({ roomNameErrorMessage: null });
                                                    }
                                                    this.handleChange(e);
                                                }}
                                            />
                                            {this.state.roomNameErrorMessage && (
                                                <span style={{ color: "red" }}>{this.state.roomNameErrorMessage}</span>
                                            )}
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            {this.state.edit ? (
                                                <CustomInput
                                                    labelText={
                                                        <div>
                                                            {i18n.t("area.locationName")}{" "}
                                                            <Tooltip title={i18n.t("tooltip.block.edit.message")}>
                                                                <HelpIcon style={{ color: "#000000" }} />
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    id="locationName"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    style={{ color: "#808080" }}
                                                    value={this.state.locationName}
                                                    disabled={true}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: 38,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <select
                                                        className="form-control searchInput"
                                                        id="areaId"
                                                        onChange={(value) => {
                                                            this.handleArea(value);
                                                        }}
                                                        value={this.state.areaId}
                                                    >
                                                        <option selected="true" value="" disabled>
                                                            {i18n.t("area.locationName")}
                                                        </option>
                                                        {areas !== null &&
                                                            areas.map((area) => (
                                                                <option key={area.id} value={area.id}>
                                                                    {area.locationName}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    {this.state.locationErrorMessage && (
                                                        <span style={{ color: "red" }}>
                                                            {this.state.locationErrorMessage}
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            {this.state.edit ? (
                                                <CustomInput
                                                    labelText={
                                                        <div>
                                                            {i18n.t("unit.unitNumber")}{" "}
                                                            <Tooltip title={i18n.t("tooltip.block.edit.message")}>
                                                                <HelpIcon style={{ color: "#000000" }} />
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    id="unitNumber"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    style={{ color: "#808080" }}
                                                    value={this.state.unitNumber}
                                                    disabled={true}
                                                />
                                            ) : (
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        marginTop: 38,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <select
                                                        className="form-control searchInput"
                                                        id="unitId"
                                                        onChange={(value) => {
                                                            this.handleUnitOptions(value);
                                                        }}
                                                        value={this.state.unitId}
                                                        disabled={this.state.disabledOption}
                                                    >
                                                        <option selected="true" value="" disabled>
                                                            {i18n.t("unit.unitNumber")}
                                                        </option>
                                                        {this.state.units !== null &&
                                                            this.state.units.map((unit) => (
                                                                <option key={unit.id} value={unit.id}>
                                                                    {unit.unitNumber}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    {this.state.unitErrorMessage && (
                                                        <span style={{ color: "red" }}>
                                                            {this.state.unitErrorMessage}
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText={i18n.t("room.rentalUnitPrice")}
                                                id="rentalUnitPrice"
                                                type={"number"}
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                value={this.state.rentalUnitPrice}
                                                onChange={(e) => {
                                                    if (e.target.value) {
                                                        this.setState({
                                                            rentalUnitPriceErrorMessage: null
                                                        });
                                                    }
                                                    this.handleChange(e);
                                                }}
                                            />
                                            {this.state.rentalUnitPriceErrorMessage && (
                                                <span style={{ color: "red" }}>
                                                    {this.state.rentalUnitPriceErrorMessage}
                                                </span>
                                            )}
                                        </GridItem>
                                    </GridContainer>
                                    <br />
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <InputLabel style={{ color: "#AAAAAA", textAlign: "left" }}>
                                                {i18n.t("room.amenity.description")}
                                            </InputLabel>
                                            <textarea
                                                className="form-control"
                                                id="amenityDescription"
                                                type="text"
                                                placeholder={i18n.t("room.input.amenity.description")}
                                                value={this.state.amenityDescription}
                                                onChange={(e) => {
                                                    this.handleChange(e);
                                                }}
                                                multiline={"true"}
                                                rows={5}
                                                maxLength={255}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <Button
                                        color="white"
                                        onClick={() => {
                                            this.props.history.push({
                                                pathname:
                                                    this.state.rooms.length > 0 && this.state.onboarding
                                                        ? "/roomList"
                                                        : this.state.onboarding
                                                        ? "/onboarding"
                                                        : "/admin/room",
                                                state: {
                                                    activePage: this.state.edit ? this.state.activePage : FIRST_PAGE,
                                                    page: ONBOARDING.roomPage,
                                                    onboarding: this.state.onboarding
                                                }
                                            });
                                        }}
                                    >
                                        <ArrowBackIcon style={{ fontSize: 18, marginRight: 10 }} />
                                        <strong>{i18n.t("common.back")}</strong>
                                    </Button>
                                    <div>
                                        {this.state.edit && (
                                            <Button color={"danger"} onClick={this._archieve}>
                                                {i18n.t("common.archieve")}&nbsp;&nbsp;&nbsp;
                                                <Icon style={{ fontSize: 18 }}>delete_forever</Icon>
                                            </Button>
                                        )}
                                        &nbsp;&nbsp;&nbsp;
                                        <Button color={this.state.edit ? "blue" : "success"} onClick={this.onEvent}>
                                            {i18n.t(this.state.edit ? "common.update" : "common.create")}
                                            <NoteAddIcon style={{ fontSize: 18, marginLeft: 10 }} />
                                        </Button>
                                    </div>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                )}
                  </div>
                {this.state.onboarding && <Footer />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAreaConstantCacheLoading: state.areaReducer.isLoadingConstant,
    isUnitConstantCacheLoading: state.unitReducer.isLoadingConstant,
    areas: state.areaReducer.areas,
    units: state.unitReducer.units
});

const mapDispatchToProps = (dispatch) => ({
    loadAreaConstant: () => dispatch(areaActions.loadAreaConstant()),
    loadUnitConstant: () => dispatch(unitActions.loadUnitConstant())
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomForm);
