import React, { Component, lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import firebase from "./views/firebase";
import FPXInstantPayRedirect from "./views/Curlec/FPXInstantPayRedirect";
import registerOwner from "./views/RegisterOwner/RegisterOwner";
import registerTenant from "./views/RegisterTenant/RegisterTenant";
import LoginAs from "./views/LoginAs/LoginAs";

// core components
import "views/CustomFormat";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import "assets/css/CustomStyle.css";
import swal from "@sweetalert/with-react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppIdleTimer from "components/IdleTimer/AppIdleTimer";
import AppModalBox from "components/Popup/AppModalBox";
import AppLoader from "components/Loader/AppLoader";
import { CONSTANT } from "views/Properties";
import AreaList from "views/Area/AreaList";
import AreaForm from "views/Area/AreaForm";
import UnitList from "views/Unit/UnitList";
import UnitForm from "views/Unit/UnitForm";
import RoomList from "views/Room/RoomList";
import RoomForm from "views/Room/RoomForm";

const PRODUCTION_MODE = Boolean(false);
if (PRODUCTION_MODE) {
    console.log = console.warn = console.error = () => {};
}

const Login = lazy(() => import("./views/Login/Login.js"));
const Admin = lazy(() => import("layouts/Admin.js"));
const OnboardingPage = lazy(() => import("./views/Onboarding/OnboardingPage.js"));
const excludedPath = ["/", "/login", "/registerOwner", "/registerTenant", "/fpx-instant-pay-redirect"];

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: false,
            showScreen: false,
            showAlertIdle: false,
            showAlertExpired: false,
            idleTime: 0,
            logoutTime: 0,
            initialLogoutTime: 0,
            firstTimeLogin: false
        };
        this.onIdleTimeout = this.onIdleTimeout.bind(this);
        this.startIdleTimer = this.startIdleTimer.bind(this);
        this.resetIdleTimer = this.resetIdleTimer.bind(this);
        this.stopIdleTimer = this.stopIdleTimer.bind(this);
    }

    onIdleTimeout() {
        this.setState({ showAlertIdle: true, logoutTime: this.state.initialLogoutTime });
        this.logoutTimer = setInterval(() => {
            this.setState({ logoutTime: this.state.logoutTime > 0 ? this.state.logoutTime - 1 : 0 });
            if (this.state.logoutTime === 0) {
                clearInterval(this.logoutTimer);
                this.setState({ showAlertIdle: false });
                this.idleTimer.stopTimer();
                localStorage.setItem("sessionTimeout", true);
                localStorage.removeItem("owner");
                localStorage.removeItem("token");
                firebase.auth().signOut();
            }
        }, 1000);
    }

    startIdleTimer(idleTime, logoutTime) {
        setTimeout(() => {
            this.setState({
                idleTime: idleTime,
                initialLogoutTime: logoutTime
            });
            this.idleTimer.startTimer(idleTime);
        }, 1000);
    }

    resetIdleTimer() {
        this.setState({ showAlertIdle: false });
        this.idleTimer.resetTimer();
    }

    stopIdleTimer() {
        this.idleTimer.stopTimer();
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                user.reload();
                if (!user.emailVerified && !process.env.REACT_APP_ADMIN_EMAIL.split("|").includes(user.email)) {
                    swal({
                        content: (
                            <span>
                                <p>{i18n.t("verify.email")}</p>
                                <a
                                    href="#/"
                                    onClick={() => {
                                        user.sendEmailVerification();
                                        swal(i18n.t("resent.email.message.success"), {
                                            icon: "success"
                                        });
                                    }}
                                >
                                    {i18n.t("verify.resentEmail")}
                                </a>
                            </span>
                        ),
                        icon: "error"
                    });
                    firebase.auth().signOut();
                } else {
                    user.getIdTokenResult().then((idTokenResult) => {
                        if (!!idTokenResult.claims.owner) {
                            this.startIdleTimer(CONSTANT.idleTime, CONSTANT.displayIdleTime);
                            const owner = JSON.parse(localStorage.getItem("owner"));
                            if (!owner) {
                                this.getOwnerProfile(user);
                            } else {
                                this.setState({ login: true, showScreen: true, firstTimeLogin: owner.firstTimeLogin });
                            }
                        } else {
                            swal("user unauthorized!", {
                                icon: "error"
                            });
                            firebase.auth().signOut();
                        }
                    });
                }
            } else {
                if (!excludedPath.includes(window.location.pathname)) {
                    window.location.pathname = "";
                } else if (localStorage.getItem("loginError")) {
                    swal({
                        title: "OOps!",
                        text: i18n.t("network.login.error"),
                        icon: "error"
                    });
                    localStorage.removeItem("loginError");
                } else if (localStorage.getItem("sessionTimeout")) {
                    this.setState({ showAlertExpired: true });
                    localStorage.removeItem("sessionTimeout");
                }
                localStorage.removeItem("owner");
                localStorage.removeItem("token");
                this.setState({ login: false, showScreen: true });
            }
        });
    }

    getOwnerProfile(user) {
        let url = process.env.REACT_APP_API_URL + "/api/getUserProfile?cn=" + user.uid;
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (!response.ok) {
                    firebase.auth().signOut();
                }
                return response.json();
            })
            .then((responseJson) => {
                if (responseJson.status) {
                    const owner = responseJson.userProfile;
                    owner.dateOfBirth = new Date(owner.dateOfBirth).customFormat("#YYYY#-#MM#-#DD#");
                    localStorage.setItem("owner", JSON.stringify(owner));
                    localStorage.setItem("token", responseJson.token)
                    this.setState({ login: true, showScreen: true, firstTimeLogin: owner.firstTimeLogin });
                } else {
                    localStorage.setItem("loginError", true);
                    firebase.auth().signOut();
                }
            })
            .catch((error) => {
                firebase.auth().signOut();
            });
    }

    render() {
        return (
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <AppIdleTimer
                        ref={(ref) => {
                            this.idleTimer = ref;
                        }}
                        onTimeout={() => this.onIdleTimeout()}
                    />

                    {this.state.showAlertIdle && (
                        <AppModalBox
                            headerText={i18n.t("common.warning")}
                            message={i18n.t("listener.countdownmsg", {
                                countdownseconds: this.state.logoutTime
                            })}
                            showCancelBtn={false}
                            okBtnText={i18n.t("common.ok")}
                            onConfirm={() => {
                                clearInterval(this.logoutTimer);
                                this.resetIdleTimer();
                            }}
                        />
                    )}

                    {this.state.showAlertExpired && (
                        <AppModalBox
                            headerText={i18n.t("common.confirmation")}
                            message={i18n.t("listener.session.expired")}
                            showCancelBtn={false}
                            okBtnText={i18n.t("common.ok")}
                            onConfirm={() => {
                                this.setState({ showAlertExpired: false });
                            }}
                        />
                    )}

                    <Suspense fallback={<AppLoader />}>
                        {this.state.showScreen && (
                            <React.Fragment>
                                {this.state.login ? (
                                    <React.Fragment>
                                        <Route path="/admin" component={Admin} />
                                        {this.state.firstTimeLogin && (
                                            <>
                                                <Route path="/onboarding" component={OnboardingPage} />
                                                <Route path="/areaList" component={AreaList} />
                                                <Route path="/areaForm" component={AreaForm} />
                                                <Route path="/unitList" component={UnitList} />
                                                <Route path="/unitForm" component={UnitForm} />
                                                <Route path="/roomList" component={RoomList} />
                                                <Route path="/roomForm" component={RoomForm} />
                                            </>
                                        )}
                                        <Redirect
                                            from="/"
                                            to={this.state.firstTimeLogin ? "/onboarding" : "/admin/dashboard"}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Switch>
                                            <Route exact={true} path={"/"} component={LoginAs} />
                                            <Route path={"/login"} component={Login} />
                                            <Route
                                                path={"/fpx-instant-pay-redirect"}
                                                component={FPXInstantPayRedirect}
                                            />
                                            <Route path={"/registerOwner"} component={registerOwner} />
                                            <Route path={"/registerTenant"} component={registerTenant} />
                                        </Switch>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </Suspense>
                </Provider>
            </I18nextProvider>
        );
    }
}

export default App;
