import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Loader from "react-loader-spinner";
import FadeIn from "react-fade-in";
import AreaManagerImpl from "./AreaManagerImpl";
import i18n from "../../i18n";
import * as areaActions from "../../redux/actions/AreaAction";
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import { NUM_OF_RECORDS_PER_PAGE, ONBOARDING } from "../Properties";
import Button from "components/CustomButtons/Button.js";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Header from "../Onboarding/Header.js";
import Footer from "../Onboarding/Footer.js";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Background from "assets/img/onboardingBackground.jpg";

class AreaList extends AreaManagerImpl {
    componentDidMount() {
        this.setState({ loading: true });
        this.props.loadAreas();
        this.intervalID = setInterval(() => {
            const { isAreaCacheLoading, areaList } = this.props;
            if (!isAreaCacheLoading) {
                this._init(areaList);
                clearInterval(this.intervalID);
            }
        }, 100);
    }

    render() {
        let displayResult = this.state.displayResult;
        return (
            <span className={this.state.onboarding ? "darkoverlay" : ""}>
                {this.state.onboarding && (
                    <div className="background">
                        <img src={Background} alt="Logo" />
                    </div>
                )}
                {this.state.onboarding && <Header />}
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)"
                    }}
                >
                    <Loader
                        visible={this.state.loading}
                        type="TailSpin" //Puff
                        color="#0099ff"
                        height={100}
                        width={100}
                    />
                </div>
                <Card
                    className="form"
                    style={{
                        maxWidth: this.state.onboarding ? "1200px" : "none",
                        minHeight: this.state.onboarding ? "800px" : "none",
                        maxHeight: this.state.onboarding ? "800px" : "none",
                        margin: this.state.onboarding ? "20px auto" : "none",
                        backgroundColor: "#eeeeee",
                        padding: this.state.onboarding ? "10px" : "none",
                        borderRadius: this.state.onboarding ? 10 : 0
                    }}
                >
                    {this.state.onboarding && (
                        <div className="alert alert-primary" role="alert">
                            <span style={{ fontSize: 22, display: "flex", fontWeight: "bold" }}>
                                <InfoOutlinedIcon size={12} />
                                &nbsp;<div>Info</div>
                            </span>
                            <span style={{ fontSize: 20 }}>
                                Location record found, this is the table that will display to you after creation of the
                                location
                            </span>
                        </div>
                    )}
                    <GridContainer>
                        {!this.state.loading && displayResult != null && (
                            <GridItem xs={12} sm={12} md={12}>
                                <Button color="success" onClick={() => this._create()}>
                                    <strong>{i18n.t("common.create")} </strong>
                                    <NoteAddIcon style={{ fontSize: 18, marginLeft: 10 }} />
                                </Button>
                                <FadeIn>
                                    <Card>
                                        <CardHeader color="yellow">
                                            <h4
                                                style={{
                                                    fontSize: "28px",
                                                    fontWeight: "bold",
                                                    fontFamily: "Franklin Gothic",
                                                    marginLeft: "20px",
                                                    marginTop: "10px"
                                                }}
                                            >
                                                {i18n.t("area.title")}
                                            </h4>
                                        </CardHeader>
                                        <CardBody>
                                            <Table
                                                tableHeaderColor="black"
                                                tableHead={[
                                                    <div style={{ width: "100%" }}>
                                                        <p className="searchLabel">{i18n.t("common.number")}</p>
                                                    </div>,
                                                    <div style={{ width: "100%" }}>
                                                        <p className="searchLabel">{i18n.t("area.locationName")}</p>
                                                        <input
                                                            type="text"
                                                            className="form-control searchInput"
                                                            id="locationName"
                                                            placeholder="search..."
                                                            onChange={(value) => {
                                                                this.searchLocationName(value, 2);
                                                            }}
                                                        />
                                                    </div>,
                                                    <div style={{ width: "100%" }}>
                                                        <p className="searchLabel">{i18n.t("area.areaName")}</p>
                                                        <input
                                                            type="text"
                                                            className="form-control searchInput"
                                                            id="name"
                                                            placeholder="search..."
                                                            onChange={(value) => {
                                                                this.searchAreaName(value, 2);
                                                            }}
                                                        />
                                                    </div>,
                                                    <div style={{ width: "100%" }}>
                                                        <p className="searchLabel">{i18n.t("area.postcode")}</p>
                                                        <input
                                                            type="number"
                                                            className="form-control searchInput"
                                                            id="postcode"
                                                            placeholder="search..."
                                                            onChange={(value) => {
                                                                this.searchPostcode(value, 2);
                                                            }}
                                                        />
                                                    </div>,
                                                    <div style={{ width: "100%" }}>
                                                        <p className="searchLabel">{i18n.t("area.city")}</p>
                                                        <input
                                                            type="text"
                                                            className="form-control searchInput"
                                                            id="city"
                                                            placeholder="search..."
                                                            onChange={(value) => {
                                                                this.searchCity(value, 2);
                                                            }}
                                                        />
                                                    </div>,
                                                    <div style={{ width: "100%" }}>
                                                        <p className="searchLabel">{i18n.t("area.state")}</p>
                                                        <input
                                                            type="text"
                                                            className="form-control searchInput"
                                                            id="state"
                                                            placeholder="search..."
                                                            onChange={(value) => {
                                                                this.searchState(value, 2);
                                                            }}
                                                        />
                                                    </div>
                                                ]}
                                                tableData={
                                                    displayResult.length > 0
                                                        ? displayResult[parseInt(this.state.activePage) - 1]
                                                        : displayResult
                                                }
                                                onClick={(index) => {
                                                    this._view(index);
                                                }}
                                            />
                                        </CardBody>
                                    </Card>
                                </FadeIn>
                            </GridItem>
                        )}
                    </GridContainer>
                    {!this.state.loading && displayResult != null && (
                        <div>
                            <div style={{ float: "right", bottom: 40, right: 30 }}>
                                <FadeIn>
                                    <Pagination
                                        hideNavigation
                                        pageRangeDisplayed={NUM_OF_RECORDS_PER_PAGE}
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={NUM_OF_RECORDS_PER_PAGE}
                                        totalItemsCount={this.state.dataCount}
                                        onChange={this.handlePageChange}
                                    />
                                </FadeIn>
                            </div>
                            <div>
                                {this.state.onboarding && (
                                    <div>
                                        <div style={{ bottom: 10, position: "absolute" }}>
                                            <Button
                                                color="white"
                                                onClick={() => {
                                                    this.props.history.push({
                                                        pathname: "/onboarding",
                                                        state: { page: ONBOARDING.tncPage }
                                                    });
                                                }}
                                            >
                                                <strong>Back</strong>
                                            </Button>
                                        </div>

                                        <div style={{ float: "right", right: 10, bottom: 10, position: "absolute" }}>
                                            <Button
                                                color="blue"
                                                onClick={() => {
                                                    this.props.history.push({
                                                        pathname: "/onboarding",
                                                        state: { page: ONBOARDING.unitPage, onboarding: true }
                                                    });
                                                }}
                                            >
                                                <strong>Next</strong>
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </Card>
                {this.state.onboarding && <Footer />}
            </span>
        );
    }
}

const mapStateToProps = (state) => ({
    isAreaCacheLoading: state.areaReducer.isLoading,
    areaList: state.areaReducer.areaList
});

const mapDispatchToProps = (dispatch) => ({
    loadAreas: () => dispatch(areaActions.loadAreas())
});

export default connect(mapStateToProps, mapDispatchToProps)(AreaList);
