import { actionType } from "../ActionType";

const INITIAL_STATE = {
    tenantList: [],
    isLoading: false,
    error: null,
};

const tenantReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionType.LOAD_TENANT_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.LOAD_TENANT_SUCCESS:
            return Object.assign({}, state, { tenantList: action.payload, isLoading: false });
        case actionType.LOAD_TENANT_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        case actionType.CREATE_TENANT_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.CREATE_TENANT_SUCCESS:
            return Object.assign({}, state, { isLoading: false });
        case actionType.CREATE_TENANT_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        default:
            return state;
    }
};

export default tenantReducer;
