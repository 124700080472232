import { actionType, constantType } from "../ActionType";

const INITIAL_STATE = {
    parkingList: [],
    parkings: [],
    parkingRefNo: {},
    isLoading: false,
    error: null,
    isLoadingConstant: false,
    errorConstant: null
};

const parkingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constantType.LOAD_PARKING_CONSTANT_START:
            return Object.assign({}, state, { isLoadingConstant: true });
        case constantType.LOAD_PARKING_CONSTANT_SUCCESS:
            return Object.assign({}, state, {
                parkings: action.parkings,
                parkingRefNo: action.parkingRefNo,
                isLoadingConstant: false
            });
        case constantType.LOAD_PARKING_CONSTANT_FAILURE:
            return Object.assign({}, state, { errorConstant: action.payload, isLoadingConstant: false });
        case actionType.LOAD_PARKING_START:
            return Object.assign({}, state, { isLoading: true });
        case actionType.LOAD_PARKING_SUCCESS:
            return Object.assign({}, state, { parkingList: action.payload, isLoading: false });
        case actionType.LOAD_PARKING_FAILURE:
            return Object.assign({}, state, { error: action.payload, isLoading: false });
        default:
            return state;
    }
};

export default parkingReducer;
