import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Logo from "assets/img/Logo.png";
import IconButton from "@material-ui/core/IconButton";
import { blackColor } from "assets/jss/material-dashboard-react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import firebase from "../firebase";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        color: blackColor,
        flexGrow: 1
    }
}));

export default function Header() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div id="topMenu">
                <AppBar position="static" style={{ background: "#FFD400" }}>
                    <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <img style={{ width: "45px" }} src={Logo} alt="logo" className={classes.img} />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Tenant Management System
                        </Typography>
                        <Tooltip title="Logout">
                            <IconButton
                                onClick={() => {
                                    localStorage.removeItem("owner");
                                    localStorage.removeItem("token");
                                    firebase
                                        .auth()
                                        .signOut()
                                        .then(() => {
                                            window.location = "/";
                                        });
                                }}
                            >
                                <ExitToAppIcon fontSize="large" />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
            </div>
        </div>
    );
}
